export const provinces = [
  {
    name: "Agrigento",
    abbreviation: "AG",
    region: "Sicilia",
  },
  {
    name: "Alessandria",
    abbreviation: "AL",
    region: "Piemonte",
  },
  {
    name: "Ancona",
    abbreviation: "AN",
    region: "Marche",
  },
  {
    name: "Arezzo",
    abbreviation: "AR",
    region: "Toscana",
  },
  {
    name: "Ascoli Piceno",
    abbreviation: "AP",
    region: "Marche",
  },
  {
    name: "Asti",
    abbreviation: "AT",
    region: "Piemonte",
  },
  {
    name: "Avellino",
    abbreviation: "AV",
    region: "Campania",
  },
  {
    name: "Bari",
    abbreviation: "BA",
    region: "Puglia",
  },
  {
    name: "Barletta-Andria-Trani",
    abbreviation: "BT",
    region: "Puglia",
  },
  {
    name: "Belluno",
    abbreviation: "BL",
    region: "Veneto",
  },
  {
    name: "Benevento",
    abbreviation: "BN",
    region: "Campania",
  },
  {
    name: "Bergamo",
    abbreviation: "BG",
    region: "Lombardia",
  },
  {
    name: "Biella",
    abbreviation: "BI",
    region: "Piemonte",
  },
  {
    name: "Bologna",
    abbreviation: "BO",
    region: "Emilia-Romagna",
  },
  {
    name: "Bolzano/Bozen",
    abbreviation: "BZ",
    region: "Trentino-Alto Adige/Südtirol",
  },
  {
    name: "Brescia",
    abbreviation: "BS",
    region: "Lombardia",
  },
  {
    name: "Brindisi",
    abbreviation: "BR",
    region: "Puglia",
  },
  {
    name: "Cagliari",
    abbreviation: "CA",
    region: "Sardegna",
  },
  {
    name: "Caltanissetta",
    abbreviation: "CL",
    region: "Sicilia",
  },
  {
    name: "Campobasso",
    abbreviation: "CB",
    region: "Molise",
  },
  {
    name: "Carbonia-Iglesias",
    abbreviation: "CI",
    region: "Sardegna",
  },
  {
    name: "Caserta",
    abbreviation: "CE",
    region: "Campania",
  },
  {
    name: "Catania",
    abbreviation: "CT",
    region: "Sicilia",
  },
  {
    name: "Catanzaro",
    abbreviation: "CZ",
    region: "Calabria",
  },
  {
    name: "Chieti",
    abbreviation: "CH",
    region: "Abruzzo",
  },
  {
    name: "Como",
    abbreviation: "CO",
    region: "Lombardia",
  },
  {
    name: "Cosenza",
    abbreviation: "CS",
    region: "Calabria",
  },
  {
    name: "Cremona",
    abbreviation: "CR",
    region: "Lombardia",
  },
  {
    name: "Crotone",
    abbreviation: "KR",
    region: "Calabria",
  },
  {
    name: "Cuneo",
    abbreviation: "CN",
    region: "Piemonte",
  },
  {
    name: "Enna",
    abbreviation: "EN",
    region: "Sicilia",
  },
  {
    name: "Fermo",
    abbreviation: "FM",
    region: "Marche",
  },
  {
    name: "Ferrara",
    abbreviation: "FE",
    region: "Emilia-Romagna",
  },
  {
    name: "Firenze",
    abbreviation: "FI",
    region: "Toscana",
  },
  {
    name: "Foggia",
    abbreviation: "FG",
    region: "Puglia",
  },
  {
    name: "Forlì-Cesena",
    abbreviation: "FC",
    region: "Emilia-Romagna",
  },
  {
    name: "Frosinone",
    abbreviation: "FR",
    region: "Lazio",
  },
  {
    name: "Genova",
    abbreviation: "GE",
    region: "Liguria",
  },
  {
    name: "Gorizia",
    abbreviation: "GO",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Grosseto",
    abbreviation: "GR",
    region: "Toscana",
  },
  {
    name: "Imperia",
    abbreviation: "IM",
    region: "Liguria",
  },
  {
    name: "Isernia",
    abbreviation: "IS",
    region: "Molise",
  },
  {
    name: "L'Aquila",
    abbreviation: "AQ",
    region: "Abruzzo",
  },
  {
    name: "La Spezia",
    abbreviation: "SP",
    region: "Liguria",
  },
  {
    name: "Latina",
    abbreviation: "LT",
    region: "Lazio",
  },
  {
    name: "Lecce",
    abbreviation: "LE",
    region: "Puglia",
  },
  {
    name: "Lecco",
    abbreviation: "LC",
    region: "Lombardia",
  },
  {
    name: "Livorno",
    abbreviation: "LI",
    region: "Toscana",
  },
  {
    name: "Lodi",
    abbreviation: "LO",
    region: "Lombardia",
  },
  {
    name: "Lucca",
    abbreviation: "LU",
    region: "Toscana",
  },
  {
    name: "Macerata",
    abbreviation: "MC",
    region: "Marche",
  },
  {
    name: "Mantova",
    abbreviation: "MN",
    region: "Lombardia",
  },
  {
    name: "Massa-Carrara",
    abbreviation: "MS",
    region: "Toscana",
  },
  {
    name: "Matera",
    abbreviation: "MT",
    region: "Basilicata",
  },
  {
    name: "Medio Campidano",
    abbreviation: "VS",
    region: "Sardegna",
  },
  {
    name: "Messina",
    abbreviation: "ME",
    region: "Sicilia",
  },
  {
    name: "Milano",
    abbreviation: "MI",
    region: "Lombardia",
  },
  {
    name: "Modena",
    abbreviation: "MO",
    region: "Emilia-Romagna",
  },
  {
    name: "Monza e della Brianza",
    abbreviation: "MB",
    region: "Lombardia",
  },
  {
    name: "Napoli",
    abbreviation: "NA",
    region: "Campania",
  },
  {
    name: "Novara",
    abbreviation: "NO",
    region: "Piemonte",
  },
  {
    name: "Nuoro",
    abbreviation: "NU",
    region: "Sardegna",
  },
  {
    name: "Ogliastra",
    abbreviation: "OG",
    region: "Sardegna",
  },
  {
    name: "Olbia-Tempio",
    abbreviation: "OT",
    region: "Sardegna",
  },
  {
    name: "Oristano",
    abbreviation: "OR",
    region: "Sardegna",
  },
  {
    name: "Padova",
    abbreviation: "PD",
    region: "Veneto",
  },
  {
    name: "Palermo",
    abbreviation: "PA",
    region: "Sicilia",
  },
  {
    name: "Parma",
    abbreviation: "PR",
    region: "Emilia-Romagna",
  },
  {
    name: "Pavia",
    abbreviation: "PV",
    region: "Lombardia",
  },
  {
    name: "Perugia",
    abbreviation: "PG",
    region: "Umbria",
  },
  {
    name: "Pesaro e Urbino",
    abbreviation: "PU",
    region: "Marche",
  },
  {
    name: "Pescara",
    abbreviation: "PE",
    region: "Abruzzo",
  },
  {
    name: "Piacenza",
    abbreviation: "PC",
    region: "Emilia-Romagna",
  },
  {
    name: "Pisa",
    abbreviation: "PI",
    region: "Toscana",
  },
  {
    name: "Pistoia",
    abbreviation: "PT",
    region: "Toscana",
  },
  {
    name: "Pordenone",
    abbreviation: "PN",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Potenza",
    abbreviation: "PZ",
    region: "Basilicata",
  },
  {
    name: "Prato",
    abbreviation: "PO",
    region: "Toscana",
  },
  {
    name: "Ragusa",
    abbreviation: "RG",
    region: "Sicilia",
  },
  {
    name: "Ravenna",
    abbreviation: "RA",
    region: "Emilia-Romagna",
  },
  {
    name: "Reggio di Calabria",
    abbreviation: "RC",
    region: "Calabria",
  },
  {
    name: "Reggio nell'Emilia",
    abbreviation: "RE",
    region: "Emilia-Romagna",
  },
  {
    name: "Rieti",
    abbreviation: "RI",
    region: "Lazio",
  },
  {
    name: "Rimini",
    abbreviation: "RN",
    region: "Emilia-Romagna",
  },
  {
    name: "Roma",
    abbreviation: "RM",
    region: "Lazio",
  },
  {
    name: "Rovigo",
    abbreviation: "RO",
    region: "Veneto",
  },
  {
    name: "Salerno",
    abbreviation: "SA",
    region: "Campania",
  },
  {
    name: "Sassari",
    abbreviation: "SS",
    region: "Sardegna",
  },
  {
    name: "Savona",
    abbreviation: "SV",
    region: "Liguria",
  },
  {
    name: "Siena",
    abbreviation: "SI",
    region: "Toscana",
  },
  {
    name: "Siracusa",
    abbreviation: "SR",
    region: "Sicilia",
  },
  {
    name: "Sondrio",
    abbreviation: "SO",
    region: "Lombardia",
  },
  {
    name: "Taranto",
    abbreviation: "TA",
    region: "Puglia",
  },
  {
    name: "Teramo",
    abbreviation: "TE",
    region: "Abruzzo",
  },
  {
    name: "Terni",
    abbreviation: "TR",
    region: "Umbria",
  },
  {
    name: "Torino",
    abbreviation: "TO",
    region: "Piemonte",
  },
  {
    name: "Trapani",
    abbreviation: "TP",
    region: "Sicilia",
  },
  {
    name: "Trento",
    abbreviation: "TN",
    region: "Trentino-Alto Adige/Südtirol",
  },
  {
    name: "Treviso",
    abbreviation: "TV",
    region: "Veneto",
  },
  {
    name: "Trieste",
    abbreviation: "TS",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Udine",
    abbreviation: "UD",
    region: "Friuli-Venezia Giulia",
  },
  {
    name: "Valle d'Aosta/Vallée d'Aoste",
    abbreviation: "AO",
    region: "Valle d'Aosta/Vallée d'Aoste",
  },
  {
    name: "Varese",
    abbreviation: "VA",
    region: "Lombardia",
  },
  {
    name: "Venezia",
    abbreviation: "VE",
    region: "Veneto",
  },
  {
    name: "Verbano-Cusio-Ossola",
    abbreviation: "VB",
    region: "Piemonte",
  },
  {
    name: "Vercelli",
    abbreviation: "VC",
    region: "Piemonte",
  },
  {
    name: "Verona",
    abbreviation: "VR",
    region: "Veneto",
  },
  {
    name: "Vibo Valentia",
    abbreviation: "VV",
    region: "Calabria",
  },
  {
    name: "Vicenza",
    abbreviation: "VI",
    region: "Veneto",
  },
  {
    name: "Viterbo",
    abbreviation: "VT",
    region: "Lazio",
  },
];
