import React, { Dispatch, FC } from "react";
import getClasses from "../../../Utils/getClasses";
import "./Switcher.scss";
interface Props {
  checked: boolean;
  setChecked: () => void | Dispatch<boolean>;
  onToggle?: () => void;
}
export const Switcher: FC<Props> = ({ checked, setChecked, onToggle }) => {
  const handleClick = () => {
    setChecked();
    onToggle?.();
  };
  return (
    <div
      className={`switch ${getClasses({ switch_actived: checked })}`}
      onClick={handleClick}
    >
      <div className="ball_switch"></div>
    </div>
  );
};

export default Switcher;
