import { useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomForm from "../../shared/components/CustomForm/CustomForm";
import { useI18n } from "../../hooks/useI18n";
import { createOtherAgenciesForm } from "../../forms/otherAgencies/createOtherAgenciesForm";
import { Form, FormValidationObject } from "../../hooks/useForm";
import { AgencyInformation } from "../../models/Agency";
import { DomainType } from "../../models/Agency";
import { editOtherAgenciesForm } from "../../forms/otherAgencies/editOtherAgenciesForm";
import Loader from "../../shared/components/Loader/Loader";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../Utils/costants";
import { RoutesRouter } from "../../models/Routes";
import { AgencyType } from "../../models/Agency";
import { getNestedValueFromString } from "../../Utils/fuctions";

export interface NewOtherAgenciesForm {
  name: string;
  abbreviation: string;
  identifier: string;
  owner: { name: string; surname: string; email: string; phoneNumber?: string };
  agencyInformation?: AgencyInformation;
  settings: {
    maxUsers: number;
    showcase: { enabled: boolean; type: DomainType; customDomain?: string };
  };
  notes?: string;
}

export interface EditOtherAgenciesForm
  extends Omit<NewOtherAgenciesForm, "owner" | "settings"> {
  settings: {
    maxUsers: number;
    showcase: {
      enabled: boolean;
    };
  };
}

interface ICreateOtherAgencies {
  classNameContainerForm?: string;
  options?: { onSubmitNavigate?: boolean };
  onClose?: () => void;
  onSubmit?: (
    otherAgencies:
      | (Partial<Form<NewOtherAgenciesForm>> & { _id: string })
      | undefined
  ) => void;
  fetchOtherAgenciesById?: boolean;
  className?: string;
}
const CreateAgencies: React.FC<ICreateOtherAgencies> = ({
  fetchOtherAgenciesById = true,
  onSubmit,
  options,
  onClose,
  classNameContainerForm = "",
  className = "",
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useI18n();
  const { id } = useParams();
  const toastAlert = useToastAlert();

  const [initialValue, setInitialValue] = useState<NewOtherAgenciesForm>();
  const [isToggleEnabled, setIsToggleEnabled] = useState<boolean>(false);

  const { getAgency, createAgency, putAgencies } = useEndpoints();

  const handleSubmit = async (
    form: Partial<Form<NewOtherAgenciesForm>>,
    next: string
  ) => {
    try {
      const toastPrefix = "agencies.toasts";

      const { owner, ...formWithoutOwner } = form;

      const { data } =
        id && fetchOtherAgenciesById
          ? await putAgencies(
              id,
              formWithoutOwner as Form<EditOtherAgenciesForm>
            )
          : await createAgency(form as Form<NewOtherAgenciesForm>);

      toastAlert({
        message: `${t(
          `${toastPrefix}.${id && fetchOtherAgenciesById ? "edited" : "added"}`
        )}`,
        subMessage: t(`${toastPrefix}.success`),
        type: "success",
        duration: DURATION_TOAST,
      });

      if (onSubmit) {
        onSubmit({ ...form, _id: data._id });
      }

      options?.onSubmitNavigate !== false && navigate(next);
    } catch (errorRequest) {
      toastAlert({
        message: t("errorsToast.unexpectedError.title"),
        subMessage: t("errorsToast.unexpectedError.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  const generateForm = (
    validators: FormValidationObject<AgencyType> | undefined = undefined
  ) => ({
    initialValue,
    validators,
  });

  const { required, invalidEmail } = {
    required: t("errorMessagesForm.required"),
    invalidEmail: t("errorMessagesForm.invalidEmail"),
  };

  const initialValueForm = {
    abbreviation: "",
    name: "",
    identifier: "",
    owner: {
      name: "",
      surname: "",
      email: "",
      phoneNumber: "",
    },
    agencyInformation: {
      companyName: "",
      vatCode: "",
      address: "",
      cities: [],
    },
    settings: {
      maxUsers: 1,
      showcase: {
        enabled: false,
        type: DomainType.STANDARD,
        customDomain: "",
      },
    },
    notes: "",
  };

  const validators = {
    name: {
      required,
    },
    abbreviation: {
      required,
      custom: (value: string) =>
        value.length < 3 || value.length > 5 || !/^[a-zA-Z0-9]+$/.test(value)
          ? t("errorMessagesForm.invalidAbbreviation")
          : "",
    },
    identifier: {
      required,
      custom: (value: string) =>
        /^[a-zA-Z0-9-]+$/.test(value)
          ? ""
          : t("errorMessagesForm.invalidIdentifier"),
    },
    "settings/maxUsers": {
      required,
    },
    "settings/showcase/type": {
      required,
    },
    "settings/showcase/customDomain": {
      custom: ((form: any) => (value: string) => {
        const type = getNestedValueFromString("settings/showcase/type", form);
        if (type === DomainType.CUSTOM) {
          if (!value) {
            return t("errorMessagesForm.required");
          }
          if (!/^(?!-)(?!.*--)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/.test(value)) {
            return t("errorMessagesForm.invalidIdentifier");
          }
        }
        return "";
      })(initialValue),
    },

    "owner/name": {
      required,
    },
    "owner/surname": {
      required,
    },
    "owner/email": {
      required,
      invalidEmail,
    },
  };

  const form = useMemo(() => generateForm(validators), [initialValue, id]);

  const fetchAgencies = async () => {
    if (id?.length !== 24) {
      toastAlert({
        message: t("errorsToast.agencyNotFound.title"),
        subMessage: t("errorsToast.agencyNotFound.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
      navigate(RoutesRouter.pageNotFound);
      return;
    }

    try {
      const { data: resp } = await getAgency(id!);

      setIsToggleEnabled(resp.enabled);

      const newForm: NewOtherAgenciesForm = {
        name: resp.name || "",
        abbreviation: resp.abbreviation || "",
        identifier: resp.identifier || "",
        owner: {
          name: resp.owner?.name || "",
          surname: resp.owner?.surname || "",
          email: resp.owner?.email || "",
          phoneNumber: resp.owner?.phoneNumber || "",
        },
        agencyInformation: {
          companyName: resp.agencyInformation?.companyName || "",
          vatCode: resp.agencyInformation?.vatCode || "",
          address: resp.agencyInformation?.address || "",
          cities: resp.agencyInformation?.cities || [],
        },
        settings: {
          maxUsers: resp.settings?.maxUsers || 0,
          showcase: {
            enabled: resp.settings?.showcase?.enabled || false,
            type: resp.settings?.showcase?.type || DomainType.STANDARD,
            customDomain: resp.settings?.showcase?.customDomain || "",
          },
        },
        notes: resp.notes || "",
      };

      setInitialValue(newForm);
      setIsToggleEnabled(resp.enabled);
    } catch (e) {
      console.error(e);

      toastAlert({
        message: t("errorsToast.unexpectedError.title"),
        subMessage: t("errorsToast.unexpectedError.description"),
        type: "error",
        duration: DURATION_TOAST,
      });

      setTimeout(() => {
        navigate(-1);
      }, 200);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    }
  };

  useEffect(() => {
    if (fetchOtherAgenciesById && id) {
      fetchAgencies();
    } else {
      setInitialValue(initialValueForm);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const props = useMemo(
    () => (id ? editOtherAgenciesForm : createOtherAgenciesForm),
    // eslint-disable-next-line
    [id]
  );

  const renderCustomForm = () => {
    return (
      //@ts-ignore
      <CustomForm
        className={classNameContainerForm}
        submit={handleSubmit}
        form={form}
        props={props}
        isToggleEnabled={isToggleEnabled}
      />
    );
  };

  return !isLoading && initialValue ? (
    <div
      className={`container-overflow container-create-customer ${className}`}
    >
      {renderCustomForm()}
    </div>
  ) : (
    <Loader />
  );
};

export default CreateAgencies;
