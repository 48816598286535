import React, { FC, Fragment, useState, useEffect } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import CustomSelect from "../../CustomSelect/CustomSelect";
import "./SelectInputCheckBox.scss";

interface ISelectInputCheckBox {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const SelectInputCheckBox: FC<ISelectInputCheckBox> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    options,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const { t } = useI18n();

  const [labelSelect, setlabelSelect] = useState(
    (options?.map(({ value, label }) => ({ label, value })) || []).filter(
      ({ value }) => {
        const selectedValues = getNestedValueFromString<string[]>(key, form);
        return (
          Array.isArray(selectedValues) &&
          selectedValues.some((selectedLanguage) => selectedLanguage === value)
        );
      }
    )
  );

  useEffect(() => {
    if (Array.isArray(labelSelect)) {
      setForm(
        key,
        labelSelect.map(({ value }) => value)
      );
    } else {
      console.warn("LabelSelect is not an array:", labelSelect);
    }
    // eslint-disable-next-line
  }, [labelSelect]);

  return (
    <>
      <div className={`col-lg-${column} container-custom-select-checkbox`}>
        <div
          className={`offset-lg-${offset} ${getClasses({
            "form-group": true,
            "is-invalid": error,
          })}`}
        >
          {label && (
            <label
              className={`form-control-label capitalize-first-letter ${
                isRequired ? "title-red" : ""
              }`}
              htmlFor="name"
            >
              {`${t(label)} ${isRequired ? "*" : ""}`}
            </label>
          )}
          <CustomSelect
            selected={labelSelect}
            error={Boolean(error)}
            checkbox={true}
            onFocus={() => handleError(key, "", false)}
            onChange={(item) => {
              setlabelSelect(item || []);
            }}
            placeholder={"selectOptions.placeholderSelect"}
            labels={(options || []).map(({ value, label }) => ({
              label,
              value,
            }))}
          />
          {error && (
            <div className="invalid-feedback">
              <div>{error}</div>
            </div>
          )}
        </div>
      </div>
      {maxColumn !== undefined && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default SelectInputCheckBox;
