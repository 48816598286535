import { Coord } from "./../models/demandCustomer";
import axios from "axios";
import { useMemo } from "react";
import {
  apiURL,
  BASIC_AUTH_PASSWORD,
  BASIC_AUTH_USERNAME,
} from "../environment/environment";
import { RespLogin } from "../models/RespLogin";
import { Consultant } from "../models/Consultant";
import { RespConsultants } from "../models/RespConsultants";
import { RespAddCustomer, RespCustomers } from "../models/RespCustomers";
import { Form } from "./useForm";
import { NewCustomerForm } from "../pages/CreateCustomer/CreateCustomer";
import { Customer } from "../models/Customer";
import {
  APPROVAL_STATUS,
  RealEstate,
  REAL_ESTATE_TYPE,
} from "../models/RealEstate";
import { RespRealEstate } from "../models/RespRealeState";
import { IGeneralForm } from "../forms/realeEstate/general";
import { IPriceForm } from "../forms/realeEstate/price";
import { IDescriptionForm } from "../forms/realeEstate/description";
import { IOtherInfoForm } from "../forms/realeEstate/otherInfo";
import { IZoneCharacteristicsForm } from "../forms/realeEstate/zoneCharacteristics";
import { NewConsultantForm } from "../pages/CreateConsultant/CreateConsultant";
import { ICertificationsForm } from "../forms/realeEstate/certifications";
import { ILocationForm } from "../forms/realeEstate/location";
import { Image } from "../forms/realeEstate/images";
import { IDemandCustomer } from "../models/demandCustomer";
import { Demand, RespDemandsCustomers } from "../models/RespDemands";
import { Agencies, BodyAgencyPatch, AgencyType } from "../models/Agency";
import { EditAgencyForm } from "../pages/Agency/Agency";
import {
  RespRealEstatesDemand,
  StatusDemand,
} from "../models/ResultRealEstateDemand";
import { Appointment } from "../models/Appointment";
import { RespAppointments } from "../models/RespAppointment";
import { appendRangeGetAppointment } from "../Utils/fuctions";
import { RespMatches, RespMatchesByCustomer } from "../models/RespMatches";
import { RespContactRequest } from "../models/ContactRequest";
import { RespMatchRealEstate } from "../models/RespMatchRealEstate";
import { EXTERNAL_INFO_STATUS } from "../models/RealEstate";
import { RespMatchesTab } from "../models/RespMatchesTab";
import {
  EditOtherAgenciesForm,
  NewOtherAgenciesForm,
} from "../pages/CreateAgencies/CreateAgencies";

const basicAuth = btoa(`${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`);

export interface RealEstateForm {
  general: Partial<Form<IGeneralForm>>;
  price: Partial<Form<IPriceForm>>;
  description: Partial<Form<IDescriptionForm>>;
  certifications: Partial<Form<ICertificationsForm>>;
  location: Partial<Form<ILocationForm>>;
  "other-info": Partial<Form<IOtherInfoForm>>;
  "zone-characteristics": Partial<Form<IZoneCharacteristicsForm>>;
  images: Partial<Form<Image>>;
}

export const useEndpoints = () =>
  useMemo(
    () =>
      ({
        //AUTH
        login: (body: Record<"email" | "password", string>) =>
          axios.post<RespLogin>(`${apiURL}/auth/login`, body, {
            headers: { Authorization: `Basic ${basicAuth}` },
          }),
        reAuth: (refreshToken: string) =>
          axios.post<RespLogin>(
            `${apiURL}/auth/re-auth`,
            { token: refreshToken },
            { headers: { Authorization: `Basic ${basicAuth}` } }
          ),
        resetPassword: ({
          token,
          password,
        }: Record<"token" | "password", string>) =>
          axios.post<RespLogin>(
            `${apiURL}/auth/reset-password`,
            { password, token },
            { headers: { Authorization: `Basic ${basicAuth}` } }
          ),
        generateToken: ({ idConsultants }: Record<"idConsultants", string>) =>
          axios.get<{ token: string }>(
            `${apiURL}/consultants/${idConsultants}/generate-token`
          ),
        me: () =>
          axios.get<
            Consultant & { isLoggedOnGoogle: boolean } & {
              agency: { settings: { demands: { zoneOffset: number } } };
            }
          >(`${apiURL}/auth/me`),

        // CUSTOMERS
        getCustomer: (id: string) =>
          axios.get<Customer>(`${apiURL}/customers/${id}`),
        getCustomers: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            demandCreated?: string;
          } & {
            [X in keyof Customer]?: string | Customer[X];
          }
        ) => axios.get<RespCustomers>(`${apiURL}/customers`, { params }),
        postCustomer: (
          id: string,
          form: Partial<Form<NewCustomerForm> & { force?: boolean }>
        ) => axios.put<"">(`${apiURL}/customers/${id}`, form),
        deleteCustomer: (id: string) =>
          axios.delete(`${apiURL}/customers/${id}`),
        addCustomer: (
          form: Partial<Form<NewCustomerForm> & { force?: boolean }>
        ) => axios.post<RespAddCustomer>(`${apiURL}/customers`, form),
        patchCustomer: (
          id: string,
          body: { enabled?: boolean; consultantId?: string }
        ) => axios.patch(`${apiURL}/customers/${id}`, { ...body }),
        // CONSULTANTS
        getConsultants: (
          params: Record<"limit" | "skip", number> & { search?: string } & {
            [X in keyof Consultant]?: string | Consultant[X];
          }
        ) => axios.get<RespConsultants>(`${apiURL}/consultants`, { params }),
        getConsultant: (id: string) =>
          axios.get<Consultant>(`${apiURL}/consultants/${id}`),
        addConsultant: (form: Partial<Form<NewConsultantForm>>) =>
          axios.post<{ consultantId: string }>(`${apiURL}/consultants`, form),
        putConsultant: (id: string, form: Partial<Form<NewConsultantForm>>) =>
          axios.put(`${apiURL}/consultants/${id}`, form),
        deleteConsultant: (id: string) =>
          axios.delete(`${apiURL}/consultants/${id}`),
        patchConsultant: (id: string, enabled: boolean) =>
          axios.patch(`${apiURL}/consultants/${id}`, { enabled }),
        postImageConsultant: (id: string, image: File) =>
          axios.post(
            `${apiURL}/consultants/${id}/profile-picture`,
            { image },
            { headers: { "Content-Type": "multipart/form-data" } }
          ),
        deleteImageConsultant: (id: string) =>
          axios.delete(`${apiURL}/consultants/${id}/profile-picture`),

        getImages: (id: string) =>
          axios.get<{ images: Image[] }>(`${apiURL}/real-estates/${id}/images`),
        postImages: (id: string, body: { image: File }) =>
          axios.post<{ Location: string; imageId: string } & Image>(
            `${apiURL}/real-estates/${id}/images`,
            body,
            { headers: { "Content-Type": "multipart/form-data" } }
          ),

        deleteImages: (id: string, idImage: string) =>
          axios.delete(`${apiURL}/real-estates/${id}/images/${idImage}`),
        putImages: (id: string, body: { imageIds: string[] }) =>
          axios.put(`${apiURL}/real-estates/${id}/images`, body),
        patchImage: (
          id: string,
          idImage: string,
          body: Partial<Exclude<Image, "url">>
        ) =>
          axios.patch(`${apiURL}/real-estates/${id}/images/${idImage}`, body),

        getImage: (id: string, idImage: string) =>
          axios.get<Image>(`${apiURL}/real-estates/${id}/images/${idImage}`),
        postImage: (id: string, idImage: string, body: Image) =>
          axios.post(`${apiURL}/real-estates/${id}/images/${idImage}`, body, {
            headers: { "Content-Type": "multipart/form-data" },
          }),

        // REALESTATE
        addRealEstate: (body: Record<"type", REAL_ESTATE_TYPE>) =>
          axios.post<{ id: string }>(`${apiURL}/real-estates`, body),
        cloneRealEstate: (id: string) =>
          axios.post<{ id: string }>(`${apiURL}/real-estates/${id}`),
        getRealEstates: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            sleeper?: boolean;
            idCostumer?: string;
            isPrivateProperty?: string;
            isCasafariAuction?: string;
            "saleSuspensionExpiration<"?: string;
            "mediation.to<"?: string;
            "!casafariId"?: string;
            coords?: string;
          } & { [X in keyof RealEstate]?: string | RealEstate[X] },
          global?: string
        ) =>
          axios.get<RespRealEstate>(`${apiURL}/real-estates${global || ""}`, {
            params,
          }),
        getRealEstatesGlobal: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            sleeper?: boolean;
            idCostumer?: string;
            "saleSuspensionExpiration<"?: string;
            "mediation.to<"?: string;
            "!casafariId"?: string;
          } & { [X in keyof RealEstate]?: string | RealEstate[X] },
          global?: string
        ) =>
          axios.get<RespRealEstate>(`${apiURL}/real-estates${global || ""}`, {
            params,
          }),
        getRealEstate: (id: string) =>
          axios.get<RealEstate>(`${apiURL}/real-estates/${id}`),
        putRealEstate: <K extends keyof RealEstateForm>(
          id: string,
          section: K,
          body: RealEstateForm[K]
        ) =>
          axios.put<RealEstate>(
            `${apiURL}/real-estates/${id}/${section}`,
            body
          ),
        patchRealEstate: (
          id: string,
          body: {
            approvalStatus?: RealEstate["approvalStatus"];
            consultant?: string;
            saleSuspensionExpiration?: Date;
          }
        ) => axios.patch<RealEstate>(`${apiURL}/real-estates/${id}`, body),
        deleteRealEstate: (id: string) =>
          axios.delete(`${apiURL}/real-estates/${id}`),
        pendingCount: () =>
          axios.get<{ count: number }>(`${apiURL}/real-estates/pending-count`),
        patchRealEstateCollaboration: (
          realEstateId: string,
          body: {
            agencyCollaborates?: EXTERNAL_INFO_STATUS | null;
            commissionCancellation?: boolean | null;
            commissionCancellationPercentage?: number;
            negotiationInProgress?: EXTERNAL_INFO_STATUS | null;
          }
        ) =>
          axios.patch(
            `${apiURL}/real-estates/${realEstateId}/external-info`,
            body
          ),

        // DEMANDS
        getDemandsCustomer: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            sleeper?: boolean;
            idConsultant?: string;
          } & { [X in keyof Demand]?: string | Demand[X] }
        ) => axios.get<RespDemandsCustomers>(`${apiURL}/demands`, { params }),
        getDemandsInProcessing: (id: string) =>
          axios.get<{ demands: string[] }>(
            `${apiURL}/demands/processing?customer=${id}`
          ),
        getDemandCustomer: (id: string) => axios.get(`${apiURL}/demands/${id}`),
        getResultsDemandCustomer: (
          id: string,
          params: Record<"limit" | "skip", number> & {
            search?: string;
            sleeper?: boolean;
            status: StatusDemand;
            source: "internal" | "external";
            isPrivateProperty?: string;
            isCasafariAuction?: string;
            agencyCollaborates?: string;
            negotiationInProgress?: string;
          } & { [X in keyof RealEstate]?: string | RealEstate[X] }
        ) =>
          axios.get<RespRealEstatesDemand>(`${apiURL}/demands/${id}/results`, {
            params,
          }),
        changeStatusMatch: (
          demandId: string,
          status: StatusDemand,
          matchIds: string[]
        ) =>
          axios.put(`${apiURL}/demands/${demandId}/results`, {
            status,
            matchIds,
          }),
        putDemandCustomer: (id: string, body: Partial<Form<IDemandCustomer>>) =>
          axios.put(`${apiURL}/demands/${id}`, body),
        postDemandCustomer: (body: {
          type: REAL_ESTATE_TYPE;
          customer: string;
          title?: string;
        }) =>
          axios.post<Record<"id" | "message", string>>(
            `${apiURL}/demands`,
            body
          ),
        deleteDemandCustomer: (id: string) =>
          axios.delete(`${apiURL}/demands/${id}`),

        // AGENCY
        getAgency: (id: string) =>
          axios.get<AgencyType>(`${apiURL}/agencies/${id}`),
        putAgency: (id: string, body: Partial<Form<EditAgencyForm>>) =>
          axios.put<AgencyType>(`${apiURL}/agencies/${id}/settings`, body),
        patchAgency: (id: string, body: BodyAgencyPatch) =>
          axios.patch(`${apiURL}/agencies/${id}`, body),
        getAgencies: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            enabled?: boolean;
          }
        ) => axios.get<Agencies>(`${apiURL}/agencies`, { params }),
        createAgency: (body: Form<NewOtherAgenciesForm>) =>
          axios.post<AgencyType>(`${apiURL}/agencies`, body),

        putAgencies: (id: string, body: Partial<Form<EditOtherAgenciesForm>>) =>
          axios.put<AgencyType>(`${apiURL}/agencies/${id}/super-admin`, body),

        toggleAgencies: (id: string) =>
          axios.post(`${apiURL}/agencies/${id}/toggle-status`),

        impersonateOwner: (agencyId: string) =>
          axios.post(`${apiURL}/auth/impersonate-owner`, { agencyId }),

        //CALENDAR

        getGoogleAuth: () =>
          axios.get<{ url: string }>(`${apiURL}/auth/google/auth-url`),
        postGoogleAuth: (body: { state: string; code: string }) =>
          axios.post<RespLogin>(`${apiURL}/auth/google/login`, body, {
            headers: { Authorization: `Basic ${basicAuth}` },
          }),
        logoutGoogleAuth: () =>
          axios.post<RespLogin>(`${apiURL}/auth/google/logout`),

        // APPOINTMENT
        getAppointments: (
          params: { "range.from": string | Date; "range.to": string | Date },
          signal: AbortSignal | undefined = undefined
        ) =>
          axios.get<RespAppointments>(
            `${apiURL}/appointments${appendRangeGetAppointment(params)}`,
            { signal }
          ),
        postAppointments: (
          body: Pick<Appointment, "location" | "notes" | "range"> &
            Partial<Pick<Appointment, "customer" | "realEstate" | "title">>
        ) => axios.post<Appointment>(`${apiURL}/appointments`, body),
        getAppointment: (id: string) =>
          axios.get<Appointment>(`${apiURL}/appointments/${id}`),
        putAppointment: (
          id: string,
          body: Pick<Appointment, "title" | "notes" | "range" | "location">
        ) => axios.put(`${apiURL}/appointments/${id}`, body),
        patchAppointment: (
          id: string,
          body: { customer?: string; realEstate?: string }
        ) => axios.patch(`${apiURL}/appointments/${id}`, body),
        deleteAppointment: (id: string) =>
          axios.delete(`${apiURL}/appointments/${id}`),

        // MATCHES
        getMatches: (
          params: Record<"limit" | "skip", number> & {
            search?: string;
            consultant?: string;
          }
        ) => axios.get<RespMatches>(`${apiURL}/matches`, { params }),
        getMatchesByTab: (
          params: Record<"limit" | "skip", number>,
          tab: string
        ) => axios.get<RespMatchesTab>(`${apiURL}/matches/${tab}`, { params }),

        getMatchesByCustomer: (id: string) =>
          axios.get<RespMatchesByCustomer>(`${apiURL}/matches/customers/${id}`),
        getMatchesByRealEstate: (
          params: Record<"limit" | "skip", number>,
          idRealestate: string
        ) =>
          axios.get<RespMatchRealEstate>(
            `${apiURL}/real-estates/${idRealestate}/matches`,
            {
              params,
            }
          ),

        // MESSAGES
        getMessages: (params: Record<"limit" | "skip", number>) =>
          axios.get<RespContactRequest>(`${apiURL}/contact-requests`, {
            params,
          }),
        deleteMessage: (id: string) =>
          axios.delete(`${apiURL}/contact-requests/${id}`),
        handleMessage: (contactId: string, handled: boolean) =>
          axios.patch<RespContactRequest>(
            `${apiURL}/contact-requests/${contactId}`,
            { handled }
          ),

        // wikicasa
        getIstatCode: (params: {
          zipCode: string;
          city: string;
          state: string;
        }) =>
          axios.get<{ municipalityId: string | null }>(
            `${apiURL}/real-estates/municipality-id`,
            { params }
          ),

        //PROVIDERS
        postOrDeletePortal: (
          id: string,
          type: "wikicasa" | "idealista" | "immobiliare" | "showcase",
          post: boolean
        ) =>
          axios[post ? "post" : "delete"](
            `${apiURL}/real-estates/${id}/providers/${type}`
          ),
      } as const),
    []
  );

export type Endpoints = ReturnType<typeof useEndpoints>;
