import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { ButtonType, Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import Button from "../../Button/Button";

import "./Radio.scss";

interface IRadio {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const Radio: FC<IRadio> = ({
  handleError,
  data: {
    options,
    multiple,
    label,
    isRequired,
    key,
    column = 4,
    columnRadio = 3,
    offset = 0,
    button,
    secondaryButton,
  },
  setForm,
  form,
  error,
}) => {
  const [itemsRadio, setItemsRadio] = useState(() => {
    const itemsRadioMapped = options?.map((option) => ({
      ...option,
      checked: (
        (getNestedValueFromString(key, form) as Array<string>) || []
      ).some((value) => value === option.value),
    }));
    return itemsRadioMapped;
  });
  const [valueRadio, setValueRadio] = useState<string | string[]>(
    multiple ? getNestedValueFromString(key, form) || [] : ""
  );
  const { t } = useI18n();

  const buttonAction = useCallback((type: ButtonType) => {
    switch (type) {
      case "selectAll":
        if (!multiple) return;
        setItemsRadio((prev) =>
          prev!.map((radio, i) => ({ ...radio, checked: true }))
        );
        break;
      case "resetAll":
        setItemsRadio((prev) =>
          prev!.map((radio, i) => ({ ...radio, checked: false }))
        );
        break;
    }
    // eslint-disable-next-line
  }, []);

  const handleclick = (index: number) => {
    setItemsRadio((prev) =>
      multiple
        ? prev!.map((radio, i) => {
            return index === i
              ? { ...radio, checked: !radio.checked }
              : { ...radio, checked: radio.checked };
          })
        : prev!.map((radio, i) => {
            return index === i
              ? { ...radio, checked: true }
              : { ...radio, checked: false };
          })
    );
  };

  useEffect(() => {
    setForm(key, valueRadio);
    // eslint-disable-next-line
  }, [valueRadio]);

  useEffect(() => {
    setValueRadio(() =>
      multiple
        ? itemsRadio!.filter(({ checked }) => checked).map(({ value }) => value)
        : itemsRadio!.find(({ checked }) => checked === true)?.value || ""
    );
    // eslint-disable-next-line
  }, [itemsRadio]);

  const radioMemo = useMemo(
    () => (
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          {label && (
            <label
              className="form-control-label text-capitalize"
              htmlFor="name"
            >
              {`${t(label)} ${isRequired ? "*" : ""}`}
            </label>
          )}
          <div className="container-square">
            {itemsRadio!.map(({ label, checked }, i) => {
              return (
                <div key={label} className={`radio col-lg-${columnRadio} mt-4`}>
                  <div
                    onClick={() => handleclick(i)}
                    onFocus={() => handleError(key, "", false)}
                    className={`container-square me-1 `}
                  >
                    <i className="icon icon-checkmark icon-square-checked"></i>
                    <div
                      className={`square ${checked ? "checked" : "unchecked"}`}
                    ></div>
                  </div>

                  <span
                    onClick={() => handleclick(i)}
                    onFocus={() => handleError(key, "", false)}
                    className={`radio-label text-capitalize pointer me-2`}
                  >
                    {t(label as keyof typeof t)}
                  </span>
                </div>
              );
            })}

            {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-12 d-flex justify-content-center">
          {secondaryButton && (
            <Button
              margin={2}
              color={"secondary"}
              onClick={() => buttonAction(secondaryButton.action)}
            >
              {secondaryButton.label}
            </Button>
          )}
          {button && (
            <Button
              margin={2}
              color={"primary"}
              onClick={() => buttonAction(button.action)}
            >
              {button.label}
            </Button>
          )}
        </div>
      </div>
    ),
    // eslint-disable-next-line
    [itemsRadio, error]
  );

  return <>{radioMemo}</>;
};

export default Radio;
