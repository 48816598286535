import { combineReducers } from "@reduxjs/toolkit";
import consultants from "./consultants";
import language from "./language";
import customers from "./customers";
import realEstate from "./realEstate";
import demandsCustomers from "./demandsCustomers";
import resultsDemandCustomer from "./resultsDemandCustomer";
import matches from "./matches";
import messages from "./messages";
import agencies from "./agencies";

export const reducers = combineReducers({
  consultants,
  language,
  customers,
  realEstate,
  demandsCustomers,
  matches,
  resultsDemandCustomer,
  messages,
  agencies,
});

export type RootState = ReturnType<typeof reducers>;
