import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import React, {
  FC,
  Fragment,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Translation } from "../../../../assets/i18n/locals/en";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import { Options } from "./options";
import "./SelectInput.scss";

interface ISelectInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const SelectInput: FC<ISelectInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    options,
    disabled,
    maxColumn,
    extraLabelWithFormValue,
  },
  form,
  setForm,
  error,
}) => {
  const { t } = useI18n();

  const handleSelect = useCallback((label: string, value: string) => {
    setlabelSelect(label);
    setForm(key, value);
    // eslint-disable-next-line
  }, []);

  const [valueForm, setValueForm] = useState<string>(
    getNestedValueFromString(key, form)
  );

  useEffect(() => {
    setValueForm(getNestedValueFromString(key, form));
    // eslint-disable-next-line
  }, [form]);

  const [labelSelect, setlabelSelect] = useState(
    getNestedValueFromString(key, form)
      ? options!.find(
          ({ value }) => value === getNestedValueFromString(key, form)
        )?.label || ""
      : ""
  );
  const relatedFieldValue =
    typeof extraLabelWithFormValue === "function"
      ? extraLabelWithFormValue(form)
      : "";

  const selectMemo = useMemo(() => {
    return (
      <>
        <div className={`col-lg-${column}`}>
          <div
            className={`offset-lg-${offset} ${getClasses({
              "form-group": true,
              "is-invalid": error,
            })}`}
          >
            <label
              className={`form-control-label capitalize-first-letter ${
                isRequired ? "title-red" : ""
              } `}
              htmlFor="name"
            >
              {`${t(label)} ${isRequired ? "*" : ""}`}
            </label>
            <div className="select-wrapper">
              <Menu>
                <MenuButton
                  disabled={Boolean(disabled)}
                  _disabled={{ color: "#9A9CA5" }}
                  border={error && "1px solid #b71922"}
                  width={"100%"}
                  className="menu-button"
                >
                  <div className="container-text-default">
                    <span
                      className={`${
                        labelSelect
                          ? "menu-button-value capitalize-first-letter"
                          : "menu-button-default"
                      }`}
                    >
                      {t(labelSelect as NestedKeys<Translation, ".">) ||
                        t("personalData.placeholderSelect")}
                    </span>

                    <ChevronDownIcon w={6} h={6} />
                  </div>
                </MenuButton>
                <MenuList
                  p={0}
                  pe={3}
                  ps={3}
                  zIndex={1000}
                  onFocus={() => handleError(key, "", false)}
                >
                  <div className="container-custom-select-input">
                    <Options
                      options={
                        options as {
                          label: "" | NestedKeys<Translation, ".">;
                          value: string;
                          checked?: boolean;
                        }[]
                      }
                      handleSelect={handleSelect}
                      valueForm={valueForm}
                    ></Options>
                  </div>
                </MenuList>
              </Menu>
              {relatedFieldValue && (
                <span className="ms-3 select-extra-label">
                  {relatedFieldValue}
                </span>
              )}
            </div>
            {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>
        {maxColumn !== undefined && <div className={`col-lg-${maxColumn}`} />}
      </>
    );
    // eslint-disable-next-line
  }, [valueForm, labelSelect, error, disabled, relatedFieldValue]);

  return <>{selectMemo}</>;
};

export default SelectInput;
