import { provinces } from "../../shared/provinces";
import { NewOtherAgenciesForm } from "../../pages/CreateAgencies/CreateAgencies";
import { ICustomForm } from "../../models/CustomForm";
import { baseManagementDomain, baseShowcaseDomain } from "../../Utils/costants";

export const editOtherAgenciesForm: ICustomForm<NewOtherAgenciesForm> = {
  title: {
    edit: "editOtherAgenciesPage.edit",
    show: "editOtherAgenciesPage.edit",
  },
  buttonRight: "agencyButtonRight",
  description: {
    edit: "editOtherAgenciesPage.fill",
    show: "",
  },
  labelBackButton: {
    edit: "createOtherAgenciesPage.back",
    show: "createOtherAgenciesPage.back",
    link: -1,
  },
  labelSubmitButton: {
    edit: "editOtherAgenciesPage.save",
    show: "editOtherAgenciesPage.save",
  },
  next: "/home/agencies",

  forms: [
    {
      fields: [
        {
          isRequired: true,
          key: "name",
          type: "text",
          label: "createOtherAgenciesPage.name",
          placeholder: "createOtherAgenciesPage.insertName",
        },
        {
          isRequired: true,
          key: "abbreviation",
          type: "text",
          label: "createOtherAgenciesPage.abbreviation",
          placeholder: "createOtherAgenciesPage.insertAbbreviation",
        },
        {
          key: "agencyInformation/companyName",
          type: "text",
          label: "createOtherAgenciesPage.companyName",
          placeholder: "createOtherAgenciesPage.insertCompanyName",
        },
        {
          key: "agencyInformation/vatCode",
          type: "text",
          label: "createOtherAgenciesPage.Vat",
          placeholder: "createOtherAgenciesPage.insertVat",
        },
        {
          key: "agencyInformation/address",
          type: "text",
          label: "createOtherAgenciesPage.address",
          placeholder: "createOtherAgenciesPage.insertAddress",
        },
        {
          column: 4,
          label: "createOtherAgenciesPage.province",
          key: "agencyInformation/cities",
          type: "select-checkbox",
          multiple: true,
          options: provinces.map((province) => ({
            label: `${province.name} - ${province.abbreviation}`,
            value: province.name,
            checked: false,
          })),
        },
      ],
      title: "",
    },
    //ADMIN DATA
    {
      title: "createOtherAgenciesPage.adminData",
      fields: [
        {
          label: "createOtherAgenciesPage.adminName",
          isRequired: true,
          key: "owner/name",
          type: "text",
          placeholder: "createOtherAgenciesPage.insertAdminName",
          disabled: true,
        },
        {
          label: "createOtherAgenciesPage.adminSurname",
          isRequired: true,
          key: "owner/surname",
          type: "text",
          placeholder: "createOtherAgenciesPage.insertAdminSurname",
          disabled: true,
        },
        {
          label: "createOtherAgenciesPage.adminEmail",
          isRequired: true,
          key: "owner/email",
          type: "text",
          placeholder: "createOtherAgenciesPage.insertAdminEmail",
          disabled: true,
        },
        {
          label: "createOtherAgenciesPage.telephone",
          key: "owner/phoneNumber",
          type: "text",
          placeholder: "createOtherAgenciesPage.insertAdminPhone",
          disabled: true,
        },
      ],
    },
    //MANAGEMENT SITE
    {
      title: "createOtherAgenciesPage.managementSite",
      fields: [
        {
          isRequired: true,
          key: "settings/maxUsers",
          type: "number",
          label: "createOtherAgenciesPage.userQuantity",
          placeholder: "createOtherAgenciesPage.insertMaxUserQuantity",
        },
        {
          type: "text-display",
          label: "createOtherAgenciesPage.managementSitePath",
          key: "identifier",
          enableCopy: true,
          valueToConcatenate: `${baseManagementDomain}`,
          column: 8,
        },
      ],
    },
    {
      title: "createOtherAgenciesPage.enableShowcase",
      fields: [
        {
          key: "settings/showcase/enabled",
          type: "switcher",
          label: "",
        },
      ],
    },
    //SHOWCASE
    {
      title: "createOtherAgenciesPage.showcase",
      fields: [
        {
          type: "text-display",
          label: "createOtherAgenciesPage.domain",
          key: "settings/showcase/type",
        },
        {
          type: "text-display",
          label: "createOtherAgenciesPage.customDomainShowcase",
          key: "identifier",
          enableCopy: true,
          valueToConcatenate: `${baseShowcaseDomain}`,
          showIfKey: "settings/showcase/type",
          showIf: (value: string) => value === "standard",
          column: 8,
        },
        {
          type: "text-display",
          label: "createOtherAgenciesPage.customDomainShowcase",
          key: "settings/showcase/customDomain",
          enableCopy: true,
          showIfKey: "settings/showcase/type",
          showIf: (value: string) => value === "custom",
          column: 8,
        },
      ],
    },

    //TODO FEATURES

    //NOTES
    {
      title: "createOtherAgenciesPage.notes",
      fields: [
        {
          column: 12,
          type: "text-area",
          key: "notes",
          placeholder: "createOtherAgenciesPage.insertNotes",
          label: "createOtherAgenciesPage.notes",
        },
      ],
    },
  ],
};
