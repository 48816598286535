export interface BodyAgencyPatch {
  landPhone?: string;
  mobilePhone?: string;
  fiscalCode?: string;
  vatCode?: string;
  location?: {
    street?: string;
    unity?: string;
    municipalityId?: string;
    latitude?: number | null;
    longitude?: number | null;
    zipCode?: string;
    state?: string;
    city?: string;
  };
  name?: string;
  abbreviation?: string;
  zoneOffset?: number;
  timeToDeleteExternalRealEstate?: number;
  timeToArchiveExternalRealEstate?: number;
}

export interface User {
  phoneNumber: string;
  _id: string;
  name: string;
  surname: string;
  email: string;
}
export interface AgencyType {
  _id: string;
  name: string;
  abbreviation: string;
  enabled: boolean;
  users: string[];
  owner: User;
  agencyInformation?: AgencyInformation;
  identifier: string;
  createdAt: Date;
  updatedAt: Date;
  settings: AgencySettings;
  notes?: string;
}

export interface Agencies {
  docs: AgencyType[];
  count: number;
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
}
export interface AgencyInformation {
  companyName?: string; // Ragione sociale
  vatCode?: string; // Partita IVA
  address?: string;
  cities?: string[];
}

export interface AgencySettings {
  images: ImagesSettings;
  demands: DemandsSettings;
  sleeperDays: SleeperDaysSettings;
  systemIntegrators: SystemIntegratorsSettings;
  showcase: ShowcaseSettings;
  maxUsers: number;
}
export interface ShowcaseSettings {
  type: DomainType;
  enabled: boolean;
  internalPropertiesUrl?: string;
  externalPropertiesUrl?: string;
  customDomain?: string;
}
export interface DemandSettingsProps {
  weight: number;
  minThreshold: number;
  maxThreshold: number;
}

export interface ImagesSettings {
  thumbnailQuality: number;
  imageQuality: number;
  sizeThreshold: number;
}

export interface DemandsSettings {
  price: DemandSettingsProps;
  totalSquareMeters: DemandSettingsProps;
  zoneOffset: number;
  zoneOffsetShowCase: number;
  timeToDeleteExternalRealEstate: number;
  timeToArchiveExternalRealEstate: number;
}

export interface WikicasaSettings {
  agencyId?: string;
  agentId?: string;
}

export interface IdealistaSettings {
  feedKey?: string;
  contactId?: string;
}

export interface ImmobiliareSettings {
  email?: string;
  agencyName?: string;
}

export interface SystemIntegratorsSettings {
  wikicasa?: WikicasaSettings;
  idealista?: IdealistaSettings;
  immobiliare?: ImmobiliareSettings;
}

export interface SleeperDaysSettings {
  realEstates: number;
  customers: number;
  demands: number;
}

export enum DomainType {
  STANDARD = "standard",
  CUSTOM = "custom",
}
