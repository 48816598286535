import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agencies } from "../../models/Agency";

interface AgenciesState {
  agencies: Agencies | undefined;
}

const initialState = {
  agencies: undefined,
} as AgenciesState;

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    setAgencies: (
      state: AgenciesState,
      action: PayloadAction<Agencies | undefined>
    ) => {
      state.agencies = action.payload;
    },
  },
});

export const { setAgencies } = agenciesSlice.actions;
export default agenciesSlice.reducer;
