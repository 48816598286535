import React, { FC } from "react";
import { Field } from "../../../../models/CustomForm";
import { useI18n } from "../../../../hooks/useI18n";
import { useClipboard, Tooltip } from "@chakra-ui/react";
import { Form } from "../../../../hooks/useForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";

interface ITextDisplay {
  form: Partial<Form<any>>;
  data: Field;
  column?: number;
  offset?: number;
  enableCopy?: boolean;
  valueToConcatenate?: string;
}

const TextDisplay: FC<ITextDisplay> = ({
  data: {
    label,
    options,
    key,
    column = 4,
    offset = 0,
    enableCopy = false,
    valueToConcatenate = "",
  },
  form,
}) => {
  const { t } = useI18n();
  const valueToShow = `${valueToConcatenate}${getNestedValueFromString(
    key,
    form
  )}`;

  const { onCopy, hasCopied } = useClipboard(valueToShow);

  return (
    <div className={`col-lg-${column}`}>
      <div className={`form-group offset-lg-${offset}`}>
        {label && (
          <label className="form-control-label text-capitalize">
            {t(label)}
          </label>
        )}

        <div className="container-square d-flex align-items-center justify-content-between flex-nowrap">
          <span
            className={`text-value ${
              !enableCopy ? "capitalize-first-letter fw-bold" : ""
            }`}
          >
            {valueToShow}
          </span>
          {enableCopy && (
            <button
              type="button"
              className="ms-2 bt-tertiary medium position-icon-left m-undefined btn-custom ml-2"
              onClick={onCopy}
            >
              {hasCopied ? t("button.copied") : t("button.copyUrl")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextDisplay;
