import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  AvatarBadge,
  IconButton,
  useQuery,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Outlet } from "react-router";
import "./Home.scss";
import { Link as LinkRouter, useLocation, useNavigate } from "react-router-dom";
import { getStorageItem } from "../../Utils/localStorage";
import getClasses from "../../Utils/getClasses";
import { IRoutesRouter, RoutesRouter } from "../../models/Routes";
import { useI18n } from "../../hooks/useI18n";
import AgencyIcon from "./../../assets/img/agency.svg";
import AgendaIcon from "./../../assets/img/agenda.svg";
import MessagesIcon from "./../../assets/img/messages.svg";
// import Bell from "../../assets/img/bell-icon.svg";
// import BellNotifications from "../../assets/img/bell-icon-notifications.svg";
import LanguageIcon from "../../assets/img/language.svg";
import { useDispatch, useSelector } from "react-redux";
import { setQueryTable } from "../../redux/reducers/realEstate";
import Svg from "../../shared/Svg/Svg";
import { useEndpoints } from "../../hooks/useEndpoints";
import dayjs from "dayjs";
import { useCookies } from "react-cookie";
import { RootState } from "../../redux/reducers";
import { setMessages } from "../../redux/reducers/messages";

interface LinkItemProps {
  name: string;
  icon: string | JSX.Element;
  link: IRoutesRouter;
}

type Parameters =
  | "consultantDetails"
  | "realEstateDetails"
  | "customers"
  | "consultants"
  | "demand-customer"
  | "agency"
  | "realestates"
  | "edit"
  | "matches"
  | "agencies";

const setHeaderRoots = (pathSplitted: string[]) =>
  pathSplitted.reduce(
    (acc, arr, index, array) => {
      const addDetail = (
        detail:
          | "consultantDetails"
          | "realEstateDetails"
          | ""
          | "demand-customer"
          | "matches-demand-customer"
      ):
        | [
            | "consultantDetails"
            | "realEstateDetails"
            | "matches-demand-customer"
            | "demand-customer"
          ]
        | [] =>
        array[index + 1] &&
        array[index + 1].length === 24 &&
        array[index + 2] !== "edit" &&
        detail
          ? [detail]
          : [];

      return (
        {
          customers: {
            parameters: [
              ...acc.parameters,
              "customers",
              ...addDetail("demand-customer"),
            ] as Parameters[],
            link: "customers",
            icon: "icon-people",
          },
          agenda: {
            parameters: [
              ...acc.parameters,
              "agenda",
              // ...addDetail("demand-customer"),
            ] as Parameters[],
            link: "agenda",
            icon: (
              <Svg className="icon-headbar" icon={`${AgendaIcon}#agenda`} />
            ),
          },
          dashboard: {
            parameters: [
              ...acc.parameters,
              "dashboard",
              // ...addDetail("demand-customer"),
            ] as Parameters[],
            link: "dashboard",
            icon: "icon-grid",
          },
          realestates: {
            parameters: [
              ...acc.parameters,
              "realestates",
              ...addDetail("realEstateDetails"),
            ] as Parameters[],
            link: "realestates",
            icon: "icon-home",
          },
          agency: {
            parameters: [
              ...acc.parameters,
              "agency",
              ...addDetail(""),
            ] as Parameters[],
            link: "agency/edit",
            icon: "icon-home",
          },
          edit: {
            ...acc,
            parameters: [...acc.parameters, "edit"] as Parameters[],
          },
          agencies: {
            parameters: [
              ...acc.parameters,
              "agencies",
              ...addDetail(""),
            ] as Parameters[],
            link: "agencies/edit",
            icon: (
              <Svg className="icon-sidebar" icon={`${AgencyIcon}#agency`} />
            ),
          },
          editAgencies: {
            ...acc,
            parameters: [...acc.parameters, "edit"] as Parameters[],
          },
          consultants: {
            parameters: [
              ...acc.parameters,
              "consultants",
              ...addDetail("consultantDetails"),
            ] as Parameters[],
            link: "consultants",
            icon: "icon-person",
          },
          matches: {
            parameters: [
              ...acc.parameters,
              "matches",
              ...addDetail("matches-demand-customer"),
            ] as Parameters[],
            link: "matches",
            icon: "icon-people",
          },
          messages: {
            parameters: [...acc.parameters, "messages"] as Parameters[],
            link: "matches",
            icon: (
              <Svg className="icon-sidebar" icon={`${MessagesIcon}#messages`} />
            ),
          },
        }[arr] || acc
      );
    },
    { parameters: [], link: "", icon: "" } as {
      parameters: Parameters[];
      link: string;
      icon: string | JSX.Element;
    }
  );

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useI18n();
  const LinkItems: Array<LinkItemProps> = useMemo(() => {
    let output: Array<LinkItemProps> =
      getStorageItem("me")?.role === "ADMIN" ||
      getStorageItem("me")?.role === "STAFF"
        ? [
            {
              name: t("home.dashboard"),
              icon: "icon-grid",
              link: "/home/dashboard",
            },
            {
              name: t("home.agenda"),
              icon: (
                <Svg className="icon-sidebar" icon={`${AgendaIcon}#agenda`} />
              ),
              link: "/home/agenda",
            },
            {
              name: t("home.consultants"),
              icon: "icon-person",
              link: "/home/consultants",
            },
            {
              name: t("home.customers"),
              icon: "icon-people",
              link: "/home/customers",
            },
            {
              name: t("home.realEstate"),
              icon: "icon-home",
              link: "/home/realestates",
            },
            {
              name: t("home.agency"),
              icon: (
                <Svg className="icon-sidebar" icon={`${AgencyIcon}#agency`} />
              ),
              link: "/home/agency/edit",
            },
            {
              name: t("home.matches"),
              icon: (
                <Svg className="icon-sidebar" icon={`${AgencyIcon}#agency`} />
              ),
              link: "/home/matches",
            },
            {
              name: "Messaggi",
              icon: (
                <Svg
                  className="icon-sidebar"
                  icon={`${MessagesIcon}#messages`}
                />
              ),
              link: "/home/messages",
            },
          ]
        : getStorageItem("me")?.role === "SUPER_ADMIN"
        ? [
            {
              name: t("home.agencies"),
              icon: (
                <Svg className="icon-sidebar" icon={`${AgencyIcon}#agency`} />
              ),
              link: "/home/agencies",
            },
          ]
        : [
            {
              name: t("home.dashboard"),
              icon: "icon-grid",
              link: "/home/dashboard",
            },
            {
              name: t("home.customers"),
              icon: "icon-people",
              link: "/home/customers",
            },
            {
              name: t("home.agenda"),
              icon: (
                <Svg className="icon-sidebar" icon={`${AgendaIcon}#agenda`} />
              ),
              link: "/home/agenda",
            },
            {
              name: t("home.realEstate"),
              icon: "icon-home",
              link: "/home/realestates",
            },
            {
              name: t("home.matches"),
              icon: "icon-home",
              link: "/home/matches",
            },
            {
              name: "Messaggi",
              icon: (
                <Svg
                  className="icon-sidebar"
                  icon={`${MessagesIcon}#messages`}
                />
              ),
              link: "/home/messages",
            },
          ];
    return getStorageItem("me")?.role === "SUPER_ADMIN"
      ? output.filter(({ name }) => name !== t("home.agency"))
      : output;
    // eslint-disable-next-line
  }, []);

  return (
    <Box minH="100vh" bg={useColorModeValue("#F6F7FB", "gray.900")}>
      <SidebarContent
        LinkItems={LinkItems}
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent LinkItems={LinkItems} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box
        ml={{ base: 0, md: 60 }}
        height={"calc(100vh - 210px)"}
        id="box"
        p="4"
      >
        {<Outlet></Outlet>}
      </Box>
    </Box>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  LinkItems: Array<LinkItemProps>;
}

const SidebarContent = ({ onClose, LinkItems, ...rest }: SidebarProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const clearCheckList = () => {
    sessionStorage.removeItem("checkList");
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        {/* <img className="logo" src={logo} alt="logo" /> */}
        <h1 className="logo-text text-uppercase">
          {getStorageItem("me")?.agency?.name || "KLEOS"}
        </h1>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map(({ link, name, icon }) => (
        <LinkRouter
          onClick={() => {
            dispatch(setQueryTable(undefined));
            clearCheckList();
          }}
          className={`${getClasses({
            "disabled-link": pathname === link,
          })}`}
          key={name}
          to={link}
        >
          <div
            className={`nav-link-custom menu-item-blue ${
              pathname.includes(link) ? "active" : ""
            }`}
            key={name}
          >
            {typeof icon === "string" ? (
              <i className={`icon ${icon}`}></i>
            ) : (
              icon
            )}
            <span className="title-custom">{name}</span>
          </div>
        </LinkRouter>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
// eslint-disable-next-line
const NavItem = ({ icon, children, ...rest }: NavItemProps) => (
  <Link
    href="#"
    style={{ textDecoration: "none" }}
    _focus={{ boxShadow: "none" }}
  >
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: "cyan.400",
        color: "white",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </Link>
);

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { pathname } = useLocation();
  const { getMessages } = useEndpoints();
  const [hasMessages, setHasMessages] = useState(false);
  const [{ link, parameters, icon }, setCurrentRoot] = useState<{
    link: string;
    icon: string | JSX.Element;
    parameters: Parameters[];
  }>({ parameters: [], link: "", icon: "" });
  const { t, changeLanguage, language: currentLanguage } = useI18n();
  const [cookie] = useCookies(["displayDate"]);
  const { messages } = useSelector(({ messages }: RootState) => messages);
  const dispatch = useDispatch();

  const getCurrentRoot = (): {
    parameters: Parameters[];
    link: string;
    icon: string | JSX.Element;
  } => setHeaderRoots(pathname.split("/"));

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkMessages();
    // eslint-disable-next-line
  }, [cookie, messages]);

  useEffect(() => {
    setCurrentRoot(getCurrentRoot());
    // eslint-disable-next-line
  }, [pathname]);

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate(RoutesRouter.login);
  };

  const fetchMessages = useCallback(async () => {
    if (!messages) {
      try {
        const { data } = await getMessages({ limit: 10, skip: 0 });
        dispatch(setMessages(data));
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, []);

  const checkMessages = useCallback(async () => {
    if (messages) {
      const date = dayjs(cookie.displayDate);
      setHasMessages(
        cookie.displayDate
          ? messages.docs.some(({ createdAt }) =>
              dayjs(createdAt).isAfter(date)
            )
          : true
      );
    }
  }, [cookie, messages]);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      justifyContent={{ base: "space-between", md: "space-between" }}
      {...rest}
    >
      {parameters.length > 1 ? (
        <div className="section-left">
          <div className="left">
            {typeof icon === "string" ? (
              <i className={`icon ${icon}`}></i>
            ) : (
              icon
            )}
            <span
              className="text ms-2 link"
              onClick={() => {
                sessionStorage.clear();
                navigate(`/home/${link}`);
              }}
            >
              {t(`home.${parameters[0]}`)} -{" "}
            </span>
            {parameters[1] === "realEstateDetails" ? (
              <span
                className="text ms-1 pointer"
                onClick={() => {
                  window.open(
                    `/home/realestates/${
                      pathname.split("/")[pathname.split("/").length - 1]
                    }/edit/general`,
                    "_blank"
                  );
                }}
              >
                {" "}
                {t("home.edit")}
              </span>
            ) : (
              <span className="text ms-1">{t(`home.${parameters[1]}`)}</span>
            )}
            {parameters[2] && (
              <span className="text ms-1"> - {t(`home.${parameters[2]}`)}</span>
            )}
          </div>
        </div>
      ) : (
        <div className="section-left">
          <div className="left">
            {typeof icon === "string" ? (
              <i className={`icon ${icon}`}></i>
            ) : (
              icon
            )}
            <span className="text ms-2">{t(`home.${parameters[0]}`)}</span>
          </div>
        </div>
      )}

      <div className="section-right">
        {/* <img className="bell-icons" src={BellNotifications} alt="" /> */}

        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu>
              {getStorageItem("me")?.role !== "SUPER_ADMIN" ? (
                <IconButton
                  mr={4}
                  size="sm"
                  variant="ghost"
                  aria-label="notification"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    sessionStorage.clear();
                    navigate("/home/messages");
                  }}
                  icon={
                    <Avatar
                      icon={<span className="icon icon-notifications"></span>}
                      size="sm"
                      backgroundColor="transparent"
                      _focusVisible={{ boxShadow: "none" }}
                    >
                      {hasMessages && (
                        <AvatarBadge boxSize="1em" bg="red.500"></AvatarBadge>
                      )}
                    </Avatar>
                  }
                />
              ) : null}
              <MenuButton py={2} _focus={{ boxShadow: "none" }}>
                <HStack pr={4}>
                  <span className="language-menu capitalize-first-letter">
                    {currentLanguage}
                  </span>
                  <img
                    src={LanguageIcon}
                    className="language-arrow"
                    alt="language arrow"
                  ></img>
                  <i className="icon icon-ch-down"></i>
                </HStack>
              </MenuButton>
              <MenuList p={0} minW={120}>
                {(["it", "en"] as const).map((language) => (
                  <MenuItem
                    key={language}
                    _hover={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                    p={0}
                  >
                    <div
                      onClick={() => changeLanguage(language)}
                      className="menu-item d-flex justify-content-between gap-1"
                    >
                      <span>{t(`home.languages.${language}`)}</span>
                      {language === currentLanguage && (
                        <i className="icon icon-checkmark icon-square-checked me-1"></i>
                      )}
                    </div>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
        <HStack spacing={{ base: "0", md: "6" }}>
          {/* <i className="icon-navbar icon-help"></i> */}
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton py={2} _focus={{ boxShadow: "none" }}>
                <HStack>
                  <div className="avatar">{`${
                    getStorageItem("me")?.name.charAt(0) || ""
                  }${getStorageItem("me")?.surname.charAt(0) || ""}`}</div>
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  ></VStack>
                </HStack>
              </MenuButton>
              <MenuList p={0} minW={120}>
                <MenuItem
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  p={0}
                >
                  <div onClick={logout} className="menu-item">
                    {t("home.logOut")}
                  </div>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </div>
    </Flex>
  );
};

export default Home;
