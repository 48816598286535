import { Tooltip } from "@chakra-ui/react";
import { Translation } from "./en";
import { getNationalities } from "./en";
import { selectOptionsIt } from "./it/selectOptions";

const it: Translation = {
  button: {
    copyUrl: "Copia URL",
    copied: "Copiato",
  },
  pageNotFound: {
    description:
      "La pagina che stai cercando potrebbe essere stata rimossa, cambiata, o temporaneamente non disponibile.",
    returnButtonConsultants: "ritorna alla pagina dei consulenti",
    returnButtonCustomers: "ritorna alla pagina dei clienti",
    returnButtonLogin: "ritorna alla pagina di login",
  },
  agencies: {
    title: "Agenzie",
    sub_title: "Pagina delle agenzie",
    headTable: {
      agency: "Agenzia",
      email: "Email",
      abbreviation: "Abbreviazione",
      showcase: "Sito vetrina",
    },
    buttonAddAgencies: "Crea agenzia",
    placeHolderSearchBar: "Cerca agenzia",
    active: "Attive",
    disabled: "Disabilitate",
    emptyTable: {
      title: "Crea la tua prima agenzia",
      description: "Inizia dalla creazione della prima agenzia",
      labelButton: "Aggiungi Agenzia",
    },
    table: {
      options: {
        edit: "Modifica",
        login: "Accedi",
        passwordLink: "Password Link",
        disable: "Disabilita",
        enable: "Abilita",
      },
    },
    edit: {
      title: "Modifica Agenzia",
      submitButton: "Modifica",
    },
    create: {
      title: "Crea Agenzia",
      submitButton: "Crea",
      cancelButton: "Annulla",
      confirmCancel: "Sei sicuro di voler annullare?",
    },
    toasts: {
      edited: "Agenzia modificata con successo",
      added: "Agenzia creata con successo",
      success: "Operazione eseguita con successo",
      errorFetch: {
        title: "Errore",
        description: "Agenzia non trovata",
      },
    },
    errors: {
      invalidAbbreviation: "Abbreviazione non valida",
      invalidIdentifier: "Identificatore non valido",
      invalidEmail: "Email non valida",
    },
  },
  consultants: {
    toasts: {
      edit: {
        consultant: "Consulente",
        edited: "Consulente modificato",
        added: " Consulente aggiunto",
        success: "con successo",
      },
      errorFetch: {
        title: "Errore",
        description: "Consulente non trovato",
      },
    },
    title: "Collaboratori",
    sub_title: "La lista dei tuoi collaboratori",
    placeHolderSearchBar: "Barra di ricerca...",
    buttonAddConsultant: "Aggiungi Collaboratore",
    publishOnShowcase: "Pubblica nella vetrina",
    enabled: "Abilitato",
    headTable: {
      name: "Nome",
      surname: "Cognome",
      email: "Email",
      isEnabled: "Attivo",
      customerState: "Stato",
      acquisitionDate: "Acquisizione",
      role: "Ruolo",
    },
    emptyTable: {
      title: "Crea il tuo primo consulente",
      description: "Inizia dalla creazione del tuo primo consulente",
      labelButton: "Aggiungi Consulente",
    },
    emptyResourcesTable: {
      title: "Nessun risultato trovato",
      description: "Prova a inserire un termine di ricerca diverso",
      resultDemandCustomer: {
        title: "Nessun immobile trovato",
      },
      emptyZone: {
        title: "Immobili non abbinati",
        description:
          "Non è possibile abbinare degli immobili alla richiesta se non è stata inserita almeno una zona ad essa.",
      },
    },
    table: {
      role: {
        ADMIN: "Amministratore",
        CONSULTANT: "Collaboratore",
        SUPER_ADMIN: "Super Amministratore",
        ANONYMOUS: "Anonimo",
        STAFF: "Staff",
      },
      options: {
        edit: "Modifica",
        delete: "Rimuovi",
        generatePassword: "Genera password",
        modalGeneratePassword: {
          title: "Genera nuova password",
          labelButtonHasCopied: "Copiato",
          labelButtonNotCopied: "Copia",
        },
        makeConsultant: "Rendi Consulente",
        makeAdmin: "Rendi Amministratore",
        enable: "Abilita",
        disable: "Disabilita",
      },
    },
    forms: {
      edit: {
        title: {
          edit: "Modifica Collaboratore",
          show: "Collaboratore",
        },
        description: {
          edit: "Compila il form per modificare un collaboratore",
        },
        labelSubmitButton: {
          edit: "Modifica Collaboratore",
          show: "Indietro",
        },
      },
      create: {
        title: { edit: "Crea Collaboratore", show: "Collaboratore" },
        description: {
          edit: "Compila il form per aggiungere un nuovo collaboratore ai clienti esistenti",
          show: "Informazioni collaboratore",
        },
        labelSubmitButton: { edit: "Aggiungi collaboratore", show: "Indietro" },
      },
      common: {
        title: "Dati Collaboratore",
        fields: {
          name: {
            label: "Nome",
            placeholder: "Mario",
          },
          surname: {
            label: "Cognome",
            placeholder: "Rossi",
          },
          email: {
            label: "Email",
            placeholder: "mariorossi@example.it",
          },
          role: {
            label: "Ruolo",
            options: {
              admin: "Amministratore",
              consultant: "Collaboratore",
              staff: "Staff",
            },
          },
          telephone: {
            label: "Telefono",
            placeholder: "Inserisci recapito telefonico",
          },
          profilePicture: "Immagine Profilo",
          enabled: "Abilitato",
        },
      },
    },
  },

  navbar: {
    resetPassword: "Resetta Password",
    logoutButton: "Logout",
    customers: "Clienti",
    consultants: "Consulenti",
    createCustomer: "Nuovo cliente",
  },

  toast: {
    customerAdded: {
      title: "Cliente creato",
      description: "cliente creato correttamente",
    },
  },
  login: {
    title: "Accedi al tuo account",
    labelEmail: "Indirizzo e-mail",
    labelPassword: "Password",
    buttonSubmit: "Accedi",
  },

  customers: {
    title: "Clienti",
    description: "La lista dei tuoi clienti",
    addCustomer: "Aggiungi Cliente",
    headTable: {
      name: "Nome",
      surname: "Cognome",
      code: "Codice",
      consultant: "Consulente",
      isEnabled: "Attivo",
      isRequest: "Richiesta",
      acquisitionDate: "Data di acquisizione",
    },
    changeConsultant: {
      title: "Cambia consulente",
      addConsultant: "Vuoi aggiungere un nuovo consulente?",
      consultant: "Consulente",
      skip: "Salta",
      confirm: "Conferma",
      toast: {
        edited: "Cliente modificato",
        added: "Cliente aggiunto",
        success: "con successo",
      },
      placeholder: "Inserisci nome cliente",
    },

    demandsCustomers: {
      title: "Richieste Cliente",
      description_one: "{{count}} richiesta effettuata per il cliente",
      description_other: "{{count}} richieste effettuate per il cliente",
      add: "Aggiungi Richiesta",
      edit: "Salva",
      firstDemand: "Inizia dalla creazione della prima richiesta",
      createFirstDemand: "Crea la sua prima richiesta",
      addCustomer: "Aggiungi Cliente",
      addConditions:
        "Aggiungi uno o più gruppi di condizioni cliccando sul tasto 'aggiungi condizioni'  poi, seleziona tutte le condizioni con il tasto più, all’interno del box.",
      necessaryConditions: "Condizioni Necessarie",
      optionalConditions: "Condizioni Opzionali",
      selectConditions: "Seleziona Condizioni",
      addConditionsButton: "Aggiungi Condizioni",
      confirm: "Conferma",
      cancel: "Annulla",
      conditionsModal: {
        search: "Cerca",
        keyWithCount_other: "condizioni selezionati",
        keyWithCount_one: "condizione selezionato",
        selectedGroups: "gruppi selezionati",
        selectedGroup: "gruppo selezionato",
        selectPlaceholder: "Seleziona area",
        reset: "Resetta Filtri",
        box: "Garage",
        kitchen: "Cucina",
        floor: "Piano",
        heatingType: "Riscaldamento",
        conservationStatus: "Stato conservazione",
        balconies: "Balconi",
        terrace: "Terrazza",
        garden: "Giardino",
        terrain: "Terreno",
        satTv: "TV Sat",
        tvAntenna: "Antenna TV",
        parkingSpaces: "Posti parcheggio",
        electricalSystem: "Impianto elettrico",
        coveredParkingSpaces: "Posti auto coperti",
        openParkingSpaces: "Posti auto scoperti",
        bikeParkingSpaces: "Posti Moto",
        camperParkingSpaces: "Posti camper",
        boatParkingSpaces: "Posti barca",
        cellar: "Cantina",
        warehouse: "Magazzino",
        petsAllowed: "Ammessi animali domestici",
        depot: "Deposito",
        elevator: "Ascensore",
        bareOwnership: "Nuda proprietà",
        airConditioning: "Aria condizionata",
        loft: "Soffitta",
        phoneSystem: "Impianto telefonico",
        parquet: "Parquet",
        lakeView: "Vista lago",
        seaView: "Vista mare",
        porch: "Veranda",
        "*": "Qualsiasi",
        suspendedSanitary: "Sanitari sospesi",
        shutters: "Persiane / Tapparelle",
        closet: "Ripostiglio",
        woodFixtures: "Finiture in legno",
        atAuction: "All'asta",
        pool: "Piscina",
        alarmPredisposed: "Predisposizione allarme",
        jacuzzi: "Vasca idromassaggio",
        independentEntrance: "Entrata indipendente",
        aluminiumFixtures: "Infissi in alluminio",
        shower: "Doccia",
        chimney: "Camino",
        safetyBox: "Cassetta di sicurezza",
        attic: "Solaio",
        shed: "Rimessa",
        stable: "Stalla",
        areas: {
          external: "Esterno",
          internal: "Interno",
          relevance: "Pertinenze",
          other: "Altro",
        },
      },
      collaborationModal: {
        title: "Collaborazione",
        description: "C'è una collaborazione attiva?",
        options: {
          yes: "Sì",
          no: "No",
          onHold: "In Attesa",
        },
        save: "Salva",
      },
      commissionModal: {
        title: "Storno Provvigionale",
        description: "È stato concordato uno storno della provvigione?",
        options: {
          yes: "Sì",
          no: "No",
        },
        placeholder: "Inserisci percentuale",
        save: "Salva",
      },
      negotiationModal: {
        title: "Trattativa in Corso",
        description: "La trattativa è in corso?",
        options: {
          yes: "Sì",
          no: "No",
          onHold: "In Attesa",
        },
        save: "Salva",
      },
      toast: {
        noPercentage: "Per favore inserisci la percentuale",
        invalidPercentage: "Percentuale non valida",
        saved: "Salvato con successo",
        reset: "Resettato con successo",
      },
    },
    table: {
      head: {
        demandStatus: "Stato richiesta",
        titleDemand: "Titolo richiesta",
        customerCode: "Codice cliente",
        creationDate: "Creata il",
        nMatches: "Incroci",
      },
      options: {
        edit: "Modifica",
        enable: "Abilita",
        disable: "Disabilita",
        requests: "Richieste",
        delete: "Elimina",
      },
    },
    labelFilterButton: "Filtra per consulente",
    labelFilterByCustomerType: "Filtra per tipo cliente",
    placeHolderSearchBar: "Barra di ricerca...",
    firstItemFilter: "Tutti",
    emptyTable: {
      title: "Crea il tuo primo cliente",
      description: "Inizia dalla creazione del tuo primo cliente",
      labelButton: "Aggiungi Cliente",
    },
  },
  resetPassword: {
    title: "Inserisci la nuova password",
    labelPassword: "Password",
    labelConfirmPassword: "Conferma Password",
    submit: "Conferma",
  },
  errorMessagesForm: {
    enterAtLeastOneZone: "Inserire almeno una zona",
    isBeforeDate: "La data deve essere dopo la data di oggi",
    isAfterDate: "La data deve essere prima della data di oggi",
    required: "Campo obbligatorio",
    invalidEmail: "Formato email non valido",
    requiredMultiSelect: "Seleziona almeno un elemento",
    invalidLengthPassword: "La lunghezza della password deve essere almeno 6",
    invalidDescriptionLength: "Deve essere lunga almeno 20 caratteri",
    invalidTaxIdCode: "Il codice fiscale potrebbe essere errato",
    invalidUrl: "Url non valido",
    existingCustomer:
      "Cliente {{context}} con questa email o numero di telefono è già esistente!",
    buttonExistingCustomerAdd: "Clicca qui per aggiungerlo ugualmente",
    buttonExistingCustomerEdit: "Clicca qui per modificarlo ugualmente",
    invalidAbbreviation:
      "L'abbreviazione deve essere di 3-5 caratteri alfanumerici",
    invalidIdentifier:
      "Il path URL può contenere solo lettere, numeri e trattini alti",
  },
  // Demand Customer

  createDemandCustomerForm: {
    title: "Richiesta cliente",
    description: {
      edit: "Compila il form per completare la richiesta",
      show: "Informazioni richiesta cliente",
    },
    labelSubmit: { edit: "Aggiungi richiesta", show: "Indietro" },
    info: {
      title: "Informazioni sulla richiesta",
      fields: {
        status: "Stato richiesta",
        placeholderStatus: "",
      },
    },
    type: "Tipologia",
    descriptionForm: "Descrizione",
    infoRealEstate: {
      title: "Specifiche sull'immobile",
      priceFrom: "Prezzo Da",
      totalSquareMetersFrom: "Totale mq. Da",
      localsFrom: "Locali Da",
      rooms: "Camere",
      bathrooms: "Bagni",
      furnitureStatus: "Arredato",
      beds: "Posti Letto",
      currentState: "Stato Attuale",
      seaLakeDistance: "Distanza mare/lago",
      contractType: "Tipo di contratto",
      contract: "Contratto",
      forStudents: "Adatto per studenti",
      missingArchitecturalBarriers: "Barriere Architettoniche",
      financialAdvice: "Consulenza finanziaria",
      loan: "Mutuo",
      cash: "Contanti",
      amount: "Importo",
      placeholderfrom: "Da",
      placeholderTo: "A",
    },
    zones: "Zone",
    featuresByCustomer: {
      title: "Caratteristiche richieste dal cliente",
      description:
        'Aggiungi uno o più gruppi di condizioni cliccando sul tasto "aggiungi condizioni" poi, seleziona tutte le condizioni con il tasto più all\'interno del box',
    },
    place: "Luogo",
    notes: {
      title: "Note",
      specificNeeds: "Esigenze specifiche",
      miscellaneous: "Varie",
    },
  },

  //Create Other Agencies
  createOtherAgenciesPage: {
    new: "Nuova Agenzia",
    fillInTheForm: "Compila il form per creare una nuova agenzia",
    name: "Nome agenzia",
    insertName: "Inserisci il nome dell'agenzia",
    abbreviation: "Abbreviazione",
    insertAbbreviation: "Inserisci un'abbreviazione (es. KLS)",
    companyName: "Ragione sociale",
    insertCompanyName: "Inserisci la ragione sociale",
    Vat: "P. IVA",
    insertVat: "Inserisci la partita IVA",
    address: "Indirizzo sede",
    insertAddress: "Inserisci via e numero",
    province: "Provincia",
    insertProvince: "Inserisci provincia",
    adminData: "Dati Admin Proprietario",
    adminName: "Nome",
    adminSurname: "Cognome",
    insertAdminName: "Inserisci il nome",
    insertAdminSurname: "Inserisci il cognome",
    adminEmail: "E-mail",
    insertAdminEmail: "Inserisci e-mail",
    telephone: "Recapito Telefonico",
    insertAdminPhone: "Inserisci numero di telefono",
    managementSite: "Gestionale",
    userQuantity: "Quantità utenti",
    insertMaxUserQuantity: "Inserisci quantità massima di utenti",
    managementSitePath: "URL Gestionale",
    insertManagementSitePath: "Inserisci il path URL",
    showcase: "Sito vetrina",
    domain: "Dominio",
    notes: "Note",
    insertNotes: "Inserisci il testo",
    back: "Annulla",
    addAgencies: "Crea Agenzia",
    standard: "Standard",
    custom: "Custom",
    enableShowcase: "Abilita sito vetrina",
    customDomainShowcase: "URL Sito vetrina",
    insertCustomDomainShowcase: "Inserisci il path URL",
  },
  editOtherAgenciesPage: {
    edit: "Modifica agenzia",
    fill: "Compila il form per modificare l'agenzia",
    show: "Mostra",
    updateForm: "Aggiorna Form",
    back: "Annulla",
    save: "Salva",
    enableAgency: "Abilita agenzia",
  },

  //Create Customer
  createCustomerPage: {
    info: "Informazioni base",
    specific: "Specifiche sul Cliente ",
    type: "Tipo cliente",
    tooltip: {
      type: `Un cliente può essere contemporaneamente di più tipi.
      Se classificato esclusivamente come 'altro' diventa semplicemente un contatto generico 
      utile per censire anagrafiche inerenti a notati, architetti, ecc.`,
      contacts: `É preferibile inserire almeno un recapito telefonico, fisso o mobile, oltre l'email.`,
    },
    personalData: "Dati anagrafici",
    contacts: "Recapiti",
    source: "Fonte di acquisizione",
    address: "Residenza",
    docs: "Documenti",
    notes: "Note",
    create: "Crea cliente",
    edit: "Modifica Cliente",
    customer: "Cliente",
    save: "Salva",
    fillToEdit: "Compila il form per modificare il cliente",
    fillInTheForm:
      "Compila il form per aggiungere un nuovo cliente ai clienti esistenti",
    addCustomer: "Aggiungi cliente",
    back: "Indietro",
  },
  address: {
    nation: "Nazione",
    province: "Provincia",
    region: "Stato Regione o Provincia",
    postalCode: "CAP",
    town: "Comune",
    city: "Città",
    zipCode: "Cap",
    address: "Indirizzo",
    civic: "N.Civico",
    istatCode: "Codice istat comunale",
    placeholder: {
      region: "Roma",
      town: "Latina",
      address: "Viale Alberato",
      postalCode: "94010",
      civic: "23",
    },
  },
  basicInfo: {
    status: {
      state: "Stato cliente",
      options: {
        active: "Attivo",
        suspended: "Sospeso",
        dealInProgress: "Trattativa in corso",
        dealConcluded: "Trattativa conclusa",
      },
    },
    rating: {
      state: "Valutazione",
      options: {
        standard: "Standard",
        low: "Basso",
        high: "Alto",
        excellent: "Eccellente",
      },
    },
    tooltip: {
      rating: "Qualità del cliente sulla base delle sue richieste",
    },
    date: "Data di acquisizione",
    creationDate: " Data di creazione",
  },
  contacts: {
    email: "Email",
    phone: { phone1: "Telefono 1", phone2: "Telefono 2" },
    placeholderPhone: "Inserisci numero",
    placeholderEmail: "Inserisci l'email",
    checkbox: {
      sms: "Non riceve SMS",
      mail: "Non riceve EMAIL",
    },
    tooltip: {
      sms: "Se spuntata, il cliente non potrà ricevere comunicazioni via sms",
      mail: "Se spuntata, il cliente non potrà ricevere comunicazioni via email",
    },
  },
  customerType: {
    done: {
      yes: "Sì",
      no: "No",
      all: "Tutti",
    },
    types: {
      applicant: "Richiedente",
      owner: "Proprietario",
      supplier: "Fornitore",
      signaler: "Segnalatore",
      other: "Altro",
    },
    tooltip: `Spunta questa opzione se l'anagrafica che stai inserendo non ha richieste 
    o immobili di proprietà e vuoi solo schedarla`,
  },
  documents: {
    docTypes: {
      identityCard: "Carta d'identità",
      passport: "Passaporto",
      drivingLicense: "Patente di guida",
      publicDeeds:
        "Atti pubblici, scritture private autenticate o documenti con firma digitale",
      declaration: "Dichiarazione dell'autorità consolare italiana",
      certificateFromAnotherProfessional:
        "Attestazione di altro professionista",
      certificateOfQualifiedIntermediaries:
        "Attestazione di intermediari abilitati, enti creditizi o finanziari CEE, banche di paesi GAFI",
      other: "Altra documentazione",
    },
    type: "Tipo di documento",
    docDetails: "Numero documento o dettagli",
    released: "Rilasciato da",
    date: "In data",
    placeholderReleased: "Inserisci comune di rilascio",
  },
  notes: {
    placeholder: "Scrivi qui eventuali informazioni aggiuntive...",
  },
  personalData: {
    types: {
      physical: "Fisica",
      juridical: "Giuridica",
      notProvided: "Non specificata",
    },
    gender: {
      male: "Maschio",
      female: "Femmina",
      notProvided: "Non fornito",
      other: "Altro",
    },
    languages: {
      arabic: "Arabo",
      chinese: "Cinese",
      french: "Francese",
      italian: "Italiano",
      english: "Inglese",
      russian: "Russo",
      spanish: "Spagnolo",
      german: "Tedesco",
    },
    person: "Persona",
    title: "Titolo",
    placeholderTitle: "Dott / Ing / Ecc.",
    surname: "Cognome",
    placeholderName: "Mario",
    placeholderSurname: "Rossi",
    placeholderSelect: "Seleziona",
    name: "Nome",
    genre: "Genere",
    birth: {
      city: "Città",
      place: "Luogo di nascita",
      date: "Data di nascita",
    },
    nationality: "Nazionalità",
    taxIdCode: "Codice fiscale",
    placeHolderTaxIdCode: "QWERTYUIOP123456",
    leng: "Lingue parlate",
  },
  source: {
    source: "Provenienza",
    sources: {
      Advertisement: "Pubblicità",
      Signaler: "Segnalatore",
      notProvided: "Non fornita",
    },
  },
  pagination: {
    elements: "Elementi per Pagina",
    pagesLabel: "di",
    items: "elementi",
  },
  home: {
    customers: "Clienti",
    consultants: "Collaboratori",
    realEstate: "Immobili",
    realestates: "Immobili",
    agency: "Agenzia",
    agencies: "Agenzie",
    agenda: "Agenda",
    dashboard: "Dashboard",
    realEstatePage: "Pagina degli Immobili",
    matches: "Incroci",
    messages: "Messaggi",
    edit: "Modifica",
    "demand-customer": "Richiesta cliente",
    "matches-demand-customer": "Risultati incrocio",
    customerDetails: "Dettagli Cliente",
    consultantDetails: "Dettagli Collaboratore",
    realEstateDetails: "Dettagli Immobile",
    logOut: "Esci",
    languages: {
      it: "Italiano",
      en: "Inglese",
    },
  },
  agency: {
    errorToast: {
      title: "Problema inatteso",
      description: "Ops! Errore imprevisto!",
    },
    successToast: {
      title: "Modifiche avvenute con successo",
      description: "",
    },
    form: {
      fields: {
        agencyUrls: {
          external: "Url immobili esterni",
          internal: "Url immobili interni",
        },
        agencyWikicasaSettings: {
          agencyId: "ID Agenzia WikiCasa",
          agentId: "ID Agente Wikicasa",
        },
        agencyIdealistaSettings: {
          feedKey: "Chiave Feed Idealista",
          contactId: "ID Contatto Idealista",
        },
        agencyImmobiliareSettings: {
          email: "Email Immobiliare",
          agencyName: "Nome Agenzia Immobiliare",
        },
        imageQuality: {
          label: "Qualità immagine",
          placeholder: "da 30 a 100",
          tooltip: "Percentuale di compressione dell'immagine",
        },
        thumbnailQuality: {
          label: "Qualità miniatura immagine",
          placeHolder: "da 30 a 100",
          tooltip: "Percentuale di compressione dell'immagine in miniatura",
        },
        zoneOffset: {
          label: "Percentuale incremento grandezza zone sulla mappa",
          tooltip:
            "Questo valore è la percentuale d'incremento delle zone sulla mappa",
        },
        zoneOffsetShowCase: {
          label: "Raggio zona indirizzo in vetrina",
          tooltip:
            "Questo valore è il raggio delle zone sulla mappa in vetrina",
        },
        timeToArchiveExternalRealEstate: {
          label: "Soglia di archiviazione immobili esterni",
          tooltip:
            "Numero di giorni dopo i quali l'immobile verrà archiviato se non attivo tra gli incroci di Casafari",
        },
        timeToDeleteExternalRealEstate: {
          label: "Soglia di eliminazione immobili esterni",
          tooltip:
            "Numero di giorni dopo i quali l'immobile verrà eliminato se non attivo tra gli incroci di Casafari",
        },
        sizeThreshold: {
          label: "Immagini non compresse",
          tooltip: "Sopra questa dimensione le immagini saranno compresse",
        },
        realestates: {
          label: "Soglia immobili dormienti",
          tooltip:
            "Soglia in giorni sopra il cui un immobile verrà contrassegnato come dormiente",
        },
        customers: {
          label: "Soglia clienti dormienti",
          tooltip:
            "Soglia in giorni sopra il cui un cliente verrà contrassegnato come dormiente",
        },
        demands: {
          label: "Soglia richieste dormienti",
          tooltip:
            "Soglia in giorni sopra il cui una richiesta verrà contrassegnato come dormiente",
        },
        name: {
          label: "Nome agenzia",
        },
        abbreviation: {
          label: "Nome abbreviazione agenzia",
        },
        mobilePhone: {
          label: "Numero mobile",
          error: "Numero no valido",
        },
        landPhone: {
          label: "Numero telefono",
          error: "Numero no valido",
        },
        fiscalCode: {
          label: "P.iva o Codice Fiscale",
          error: "P.iva o Codice fiscale non validi",
        },
        place: {
          label: "Luogo",
          error: "Luogo non valido",
        },
        istatCode: {
          label: "Codice Istat",
        },
      },
      errorImage: "Deve essere tra 30 a 100",
      required: "Valore Obbligatorio",
      greaterThanZero: "Deve essere maggiore di zero",
      nameAgency: "Il nome deve essere compreso tra 3 e 25 caratteri",
      abbreviation: "L'abbreviazione deve essere compreso tra 3 e 5 caratteri",
      errorTimeToArchiveExternalRealEstateRange:
        "La soglia di archiviazione deve essere compresa tra 1 e 365",
      errorTimeToArchiveGreaterThanTimeToDelete:
        "La soglia di archiviazione deve essere inferiore alla soglia di eliminazione",
      errorTimeToDeleteExternalRealEstateRange:
        "La soglia di eliminazione deve essere compresa tra 1 e 365",
    },
    editAgency: {
      title: "Modifica Agenzia",
      description: "Impostazioni agenzia",
      submit: "Salva",
    },
  },
  realEstate: {
    clone: "Clona",
    edit: "Modifica",
    delete: "Elimina",
    placeHolderSearchBar: "Barra di ricerca...",
    onSale: "In vendita",
    forRent: "In Affitto",
    agent: "Agente",
    otherFilters: "Ulteriori filtri",
    hideFilters: "Nascondi filtri",
    alertDeniedPublish: {
      title:
        "Pubblicazione immobile non riuscita per mancanza di alcuni campi obbligatori",
      description: "Clicca per aggiungerli",
    },
    stateMenuButtonLabels: {
      approval: "Stato Approvazione",
      market: "Stato Mercato",
    },
    consultant: "Consulente",
    emptyTable: {
      description: "Inizia dalla creazione del tuo primo immobile",
      title: "Crea il tuo primo Immobile",
      labelButton: "Aggiungi Immobile",
    },
    addRealEstate: {
      title: "Crea Immobile",
      type: "Tipologia",
      RESIDENTIAL: "Residenziale",
      COMMERCIAL: "Commerciale",
      LAND: "Terreni",
      NOT_PROVIDED: "Non specificato",
      add: "Aggiungi",
    },
    approvalStatus: {
      draft: "Bozza",
      pending: "Pre-approvazione",
      approved: "Attivo",
      sold: "Venduto",
      rented: "Affittato",
      suspended: "Sospeso",
      soldSuspended: "Venduto Sospensiva",
    },
    selectFilters: {
      filters_zero: "Seleziona i filtri",
      filters_one: "{{count}} Selezionato",
      filters_other: "{{count}} Selezionati",
    },
    select: "Seleziona",
    buttonsFilters: {
      reset: "Resetta",
      apply: "Applica",
      unlockFilters: "Sblocca filtri",
      resetFilters: "Resetta filtri",
      lockFilters: "Blocca filtri",
    },
    forms: {
      landDescription: {
        landKind: "Tipo di Terreno",
        title: "Descrizione Terreno",
      },
      residential: {
        generalForm: {
          errorToast: {
            title: "Problema Inatteso",
            description: "Ops! Problema imprevisto! ",
          },
          title: {
            edit: "Generale",
            show: "Generale",
          },
          description: {
            edit: "Informazioni generali",
            show: "Informazioni generali",
          },
          labelSubmitButton: {
            edit: "Modifica",
            show: "Avanti",
          },
          labelBackButton: {
            edit: "Esci",
            show: "Esci",
          },
          titles: {
            informationRealState: {
              title: "Informazioni sull'immobile",
              labels: {
                acquisitionDate: "Data di acquisizione",
                state: "Stato",
                mediationType: "Mediazione",
                mediationFrom: "Da",
                mediationTo: "A",
              },
            },
            property: {
              title: "Proprietà",
              labels: {
                owner: "Proprietario",
                tooltip: "Aggiungi un nuovo cliente",
              },
            },
            details: {
              title: "Dettagli",
              labels: {
                contractType: "Contratto",
                rentDetails: {
                  contractType: "Tipo di contratto",
                  requestedWarranty: "Garanzia richiesta",
                  contractRegistration: "Registrazione contratto",
                  propertyType: "Tipologia",
                },
                checkbox: {
                  forStudents: {
                    option: "Adatto per studenti",
                    tooltip:
                      "Indica se l'immobile può essere adatto per essere locato a studenti",
                  },
                  petsAllowed: { option: "Ammessi animali domestici" },
                  bareOwnership: {
                    option: "Nuda proprietà",
                  },
                  dryTax: "Cedolare Secca",
                },
              },
            },
            virtualTour: {
              title: "Media",
              labels: {
                virtualTourUrl: "Tour virtuale",
                media: "Video",
              },
            },
            availability: {
              title: "Disponibilità",
              labels: {
                currentState: "Stato attuale",
                availableNow: {
                  option: "Disponibile subito",
                  tooltip:
                    "Se attualmente l'immobile è impegnato in quanto dato in locazione o non agibile, indicare la presunta data per la disponibilità rimuovendo questa spunta",
                },
                videoUrl: "Url Video",
                notes: "Note",
                details: "Ulteriori dettagli disponibilità",
              },
            },
          },
        },
      },
      certifications: {
        title: "Certificazioni",
        description: {
          edit: "Definisci le certificazioni che ha l'immobile",
          show: "Certificazioni dell'immobile",
        },
        labelSubmit: { edit: "Modifica", show: "Avanti" },
        titles: {
          energyCertification: {
            title: "Classe energetica e indice di prestazione",
            labels: {
              class: "Classe energetica",
              performanceIndex: "EP glnr",
              performanceUnit: "Unità di misura",
              almostZeroEnergy: "Edificio a energia quasi zero",
              energyPerformanceWinter: "Performance invernali",
              energyPerformanceSummer: "Performance estive",
            },
          },
          energyCertificationOld: {
            title:
              "Classe energetica e indice di prestazione (Per attestati antecedenti Ottobre 2015)",
            labels: {
              class: "Classe energetica",
              performanceIndex: "EP glnr",
              performanceUnit: "Unità di misura",
              notes: "Note",
            },
          },
          soundCertification: {
            title: "Certificazione acustica",
            labels: {
              class: "Classe",
              notes:
                "Ulteriori dettagli e note inerenti alla Certificazione acustica",
            },
          },
        },
      },
      zoneCharacteristicsForm: {
        title: "Caratteristiche di zona",
        description: {
          edit: "Spunta altre eventuali caratteristiche dell'immobile",
          show: "caratteristiche dell'immobile",
        },
        labelSubmit: {
          edit: "Modifica",
          show: "Avanti",
        },
        selectAll: "Seleziona tutti",
        deselectAll: "Deseleziona tutti",
      },
      description: {
        cards: {
          balconies: "Balconi",
          terrace: "Terrazzo",
          garden: "Giardino",
          terrain: "Terreno",
          kitchen: "Cucina",
          garage: "Garage",
          coveredParkingSpaces: "Posti auto coperti",
          openParkingSpaces: "Posti auto scoperti",
          bikeParkingSpaces: "Posti moto",
          camperParkingSpaces: "Posti Camper",
          boatParkingSpaces: "Posti barca",
          cellar: "Cantina",
          warehouse: "Magazzino",
          depot: "Deposito",
          loft: "Soffitta",
          attic: "Solaio",
          shed: "Rimessa",
          stable: "Stalla",
        },
        kitchen: "Cucina",
        title: "Descrizione",
        description: {
          edit: "Aggiungi alcune caratteristiche dell'immobile",
          show: "caratteristiche dell'immobile",
        },
        labelSubmit: {
          edit: "Modifica",
          show: "Avanti",
        },
        features: {
          title: "Caratteristiche dell'immobile",
          labels: {
            totalSquareMeters: "Metri Quadrati",
            totalSquareMetersWalkable: "Metri quadrati Calpestabili",
            locals: "Locali",
            showcases: "Vetrine",
            rooms: "Camere da letto",
            bathrooms: "Bagni",
            description: "Descrizione",
            landKind: "Tipo di Terreno",
            buildabilityIndex: "Indice di Edificabilità",
            buildableSquareMeters: "M² edificabili",
            agriculturalSquareMeters: "M² agricoli",
            exposition: "Esposizione",
            constructionPresent: "Costruzione Presente",
            possibleProduction: "Possibile Realizzazione",
            approvedProject: "Progetto Approvato",
            easementOfPassage: "Servitù di Passaggio",
            preEmptionRights: "Diritti di Prelazione",
            plantations: "Piantagioni",
          },
        },
        deatils: "Dettaglio dei locali principali e delle pertinenze",
        otherRooms: "Ulteriori stanze e locali",
        heating: {
          title: "Riscaldamento",
          labels: {
            type: "Tipo",
            fuel: "Combustibile",
            system: "Impianto",
            radiators: "Termosifoni",
            expenses: "Spese di riscaldamento mensili",
          },
        },
        additionalFeatures: {
          title: "Ulteriori caratteristiche",
          labels: {
            quality: "Qualità",
            habitability: "Abitabilità",
            conservationsStatus: "Stato conservazione",
            windows: "Infissi / Finestre",
            alarm: "Allarme",
            intercom: "Videocitofono",
            reception: "Portineria",
            furniture: "Arredato",
            furnishedDescription: "Breve descrizione arredamento",
            wardrobe: "Guardaroba",
            exposition: "Esposizione",
          },
        },
        roomPertinenceDeatils: {
          select: "Seleziona",
          equipment: "Dotazione",
          type: "Tipo",
          dimension: "Dimensioni",
          errorDimension: "Deve rispettare la sinstassi NxN",
          errorType: "Tipo è un campo obbligatorio",
          addRooms: "Aggiungi stanze",
          selectRoomsType: "Seleziona il tipo di stanza",
        },
      },
      images: {
        labelSubmit: {
          edit: "Esci",
          show: "Esci",
        },
        images: "Immagini",
        uploadImages: "Carica le foto del tuo immobile",
        uploadImage: "Carica le foto",
        dragClicktouploadImages:
          "Trascina o clicca sull'apposito bottone per aggiungere una o più immagini",
        addImage: " Aggiungi Immagine",
        deleteAllImage: "Elimina tutte",
        uploadPhoto: "Carica Foto",
        dragHere: "Trascina qui o",
        toast: {
          success: {
            title: "Modifiche riuscite",
            description: "Dati aggiornati",
          },
          error: {
            title: "Problema inatteso",
            descrition: "Ops! Errore imprevisto",
          },
          minLength: {
            title: "Requisiti non soddisfatti",
            descrition: "Ci devono essere minimo 10 immagini",
          },
          minLengthLand: {
            title: "Requisiti non soddisfatti",
            description: "Ci devono essere minimo 5 immagini",
          },
          approved: {
            title: "Modifiche riuscite",
            description: "Dati aggiornati, nuovo stato immobile: Approvato",
          },
          pending: {
            title: "Modifiche riuscite",
            description:
              "Dati aggiornati, nuovo stato immobile: in Pre-pubblicazione",
          },
          draft: {
            title: "Modifiche riuscite",
            description: "Dati aggiornati, nuovo stato immobile: Bozza",
          },
          failedCompression: {
            title: "Compressione immagine {{context}} non riuscita!",
            description: "Provare ad inserire un immagine con un peso minore",
          },
        },
      },
      location: {
        title: "Ubicazione",
        labelSubmit: {
          edit: "Modifica",
          show: "Avanti",
        },
        architecturalBarriers: "Barriere architettoniche",
        address: "Indirizzo e coordinate",
        zone: "Zona",
        latitude: "Latitudine",
        longitude: "Longitudine",
        floor: "Piano",
        totalFloors: "Piani totali",
        position: "Posizione",
        info: {
          title: "Informazioni sullo stabile",
          labels: {
            constructionYear: "Anno di costruzione",
            totalApartments: "Appartamenti totali",
            elevator: "Ascensore",
          },
        },
      },
      price: {
        title: "Prezzo",
        description: {
          edit: "Definisci i costi dell'immobile",
          show: "Costi dell'immobile",
        },
        labelSubmit: {
          edit: "Modifica",
          show: "Avanti",
        },
        requestedPrice: "Prezzo Richiesto",
        estimatedValue: "Valore di Stima",
        condominiumFees: "Spese condominiali mensili",
        box: {
          tooltip:
            "Attivare questa opzione se il prezzo include anche un eventuale box",
          option: "Box incluso nel prezzo",
        },
        priceOption: {
          title: "Opzioni prezzo",
          negotiable: {
            option: "Trattabile",
            tooltip:
              "Se selezionato farà apparire la dicitura 'Trattabile' vicino al prezzo riportato nelle stampe e sul web",
          },
          startingFrom: {
            option: "A partire da",
            tooltip:
              "Se selezionato farà apparire la dicitura 'A partire da' vicino al prezzo riportato nelle stampe e sul web",
          },
        },
      },
      otherInfo: {
        title: "Altri Dati",
        description: {
          edit: "Spunta altre eventuali caratteristiche dell'immobile",
          show: "caratteristiche dell'immobile",
        },
        labelSubmit: {
          edit: "Modifca",
          show: "Avanti",
        },
      },
      stepperForm: {
        next: "Salva e vai Avanti",
        back: "Indietro",
        labels: {
          general: "Generale",
          price: "Prezzo",
          location: "Ubicazione",
          description: "Descrizione",
          certifications: "Certificazioni",
          otherInfo: "Altri dati",
          zoneCharacteristics: "Caratteristiche di zona",
          images: "Immagini",
        },
      },
      commercialDescription: {
        squareMetersCovered: "M² coperti",
        squareMetersUncovered: "M² scoperti",
        squareMetersCoverable: "M² copribili",
        showcaseExposition: "Esposizione Vetrine",
        roomsPertinence: "Dettaglio dei locali principali e delle pertinenze",
        additionalRooms: "Ulteriori stanze e locali",
        additionalDetails: {
          labels: {
            allowsManagement: "Consente la gestione",
            allowsManagementOption: "Possibilità di una gestione",
            activitiesToo: "Anche attività",
            activityValue: "Attività",
            fee: "Canone",
            roomSeparator: "Separatore di stanza",
            context: "Contesto",
            weeklyRest: "Riposo settimanale",
            averageRevenueFrom: "Reddito medio da",
            averageRevenueTo: "Reddito medio a",
            from: "Da",
            to: "A",
          },
        },
      },
      compactRealEstate: {
        title: "Descrizione dell'immobile",
        description: "Descrizione dell'immobile",
        map: "Mappa",
        specificInformation: {
          title: "Informazioni specifiche dell'immobile",
          floor: "Piano",
          contractType: "Contratto",
          propertyType: "Tipologia dell'immobile",
          distanceSeaLake: "Distanza Mare/Lago",
          architecturalBarriers: "Barriere Architetoniche",
          showcases: "Vetrine",
          elevator: "Ascensore",
          yes: "Sì",
          no: "No",
          price: "Costo",
          position: "Posizione",
          buildingCosts: "Spese del condominio",
          constructionYear: "Anno di costruzione",
          state: "Stato",
          totalFloors: "Totale piani",
          floors: "Piani",
          locals: "Locali",
          rooms: "Stanze",
          bathrooms: "Bagni",
          surface: "Superficie",
          walkable: "calpestabili",
          heating: "Riscaldamento",
        },
        price: {
          title: "Prezzo",
          requestedPrice: "Prezzo richiesto",
          estimatedValue: "Valore stimato",
          monthlyCondominiumExpenses: "Spese condominiali mensili",
          PriceOption: {
            title: "Prezzo richiesto",
            negotiable: "Negoziabile",
            notNegotiable: "Non negoziabile",
          },
        },
        generalInformation: {
          title: "Informazioni generali",
          lastUpdate: "Ultimo aggiornamento",
          origianlConsultant: "Consulente originale",
          dated: "Inserito in data",
          state: "Stato",
          yearOfConstruction: "Anno di costruzione",
          exclusiveAssignment: "Incarico in esclusiva",
          note: "Note",
          video: "Video immobile:",
          startOn: "Inizia il",
          endsOn: "scade il",
          goShowCase: "Vai al sito Kleos",
          goToSite: "Vai al portale esterno",
          telephone: "Telefono",
          email: "Email",
          agencyOrPrivate: "Agenzia o proprietario",
          historyPrice: "Storico prezzo",
        },
        features: {
          title: "Caratteristiche",
          walkableMeters: "Metri calpestabili",
          fuel: "Combustibile",
          system: "Impianto",
          radiators: "Termosifoni",
          monthlyExpenses: "Spese di riscaldamento mensili",
          landKind: "Tipo di Terreno",
          propertyType: "Tipologia",
          buildabilityIndex: "Indice di edificabilità",
          buildableSquareMeters: "M² edificabili",
          agriculturalSquareMeters: "M² agricoli",
          constructionPresent: "Costruzione presente",
          possibleProduction: "Possibile realizzazione",
          approvedProject: "Progetto approvato",
        },
        additionalFeatures: {
          title: "Caratteristiche aggiuntive",
          quality: "Qualità",
          habitability: "Abitabilità",
          conservationStatus: "Stato conservazione",
          windows: "Infissi/finestre",
          intercom: "Citofono",
          reception: "Ricezione",
          furnished: "Arredato",
          exposition: "Esposizione",
          easementOfPassage: "Servitù di Passaggio",
          preEmptionRights: "Diritti di Prelazione",
          plantations: "Piantagioni",
        },
        zoneCharacteristics: {
          title: "Caratteristiche di zona",
        },
        otherCharacteristics: {
          title: "Altre caratteristiche",
        },
        energyEfficiency: {
          title: "Classe energetica e indice di performance",
          class: "Classe",
          EpGinr: "EP gInr",
          performanceUnit: "Unità",
          winterConsumption: "Consumo invernale",
          summerConsumption: "Consumo estivo",
        },
      },
    },
  },
  zone: {
    airport: "Aeroporto",
    bar: "Bar",
    tennisCourts: "Campi da tennis",
    shoppingCenters: "Centri commerciali",
    sportsComplexes: "Complessi sportivi",
    MountainSkiLifts: "Montagna/Impianti da Sci",
    gym: "Palestra",
    pinewood: "Pineta",
    middleSchool: "Scuole medie",
    ringRoad: "Tangenziale",
    postOffice: "Ufficio postale",
    dayCare: "Asilo",
    wood: "Bosco",
    canal: "Canale",
    congressCenter: "Centro congressi",
    lake: "Lago",
    shops: "Negozi",
    playground: "Parco giochi",
    bikePath: "Pista ciclabile",
    highSchool: "Scuole superiori",
    publicTransport: "Trasporti pubblici",
    motorway: "Autostrada",
    footballFields: "Campi da calcio",
    wellnessCenter: "Centri benessere",
    religiousComplexes: "Complessi religiosi",
    sea: "Mare",
    hospital: "Ospedale",
    park: "Parco",
    elementarySchool: "Scuole elementari",
    trainStation: "Stazione ferroviaria",
    municipalOffices: "Uffici comunali",
  },
  nationalities: {
    ...getNationalities(),
  },
  selectOptions: {
    ...selectOptionsIt,
  },
  cardRealEstate: {
    createdAt: "Creato il {{context}}",
    state: "Stato",
    setState: "Imposta a",
    icons: {
      bath: "bagni",
      room: "camere",
      local: "locali",
    },
  },
  editImage: {
    title: "Modifica la tua foto",
    placeHolderAddImage: "Aggiungi Stanza",
    paceholderTextArea: "Breve descrizione della stanza",
    tooltip: "La foto sarà visibile solo per te",
    delete: "Elimina",
    save: "Salva ed Esci",
    privateImage: "Immagine Privata",
    confirm: "Conferma",
    cancel: "Annulla",
    textConfirmCancel: "Conferma eliminazione immagine",
    textConfirmCancelAll: "Conferma eliminazione di tutte le immagini",
    imagesTenMin:
      "Cancellazione immagine non consentita, minimo 10 immagini sono obbligatorie in un'immobile reso pubblico",
    imagesFiveMin:
      "Cancellazione immagine non consentita, minimo 5 immagini sono obbligatorie in un'immobile reso pubblico",
  },
  loading: "Caricamento",
  resultsDemandCustomer: {
    title: "Risultati richiesta",
    description: "La lista dei risultati della richiesta",
  },
  resultsMatchesCustomer: {
    title: "Risultati incrocio per",
    description: "La lista dei risultati dell'incrocio",
  },
  gallery: {
    showGallery: "Vedi galleria",
    photo: "Foto",
    planimetry: "Planimetria",
    videoTour: "Video Tour",
    media: "Media",
    publicPhoto: "Foto pubbliche",
    privatePhoto: "Foto private",
    errorUrl: "Ops! Problemi ad accedere all'url",
  },
  alert: {
    clone: {
      title: "Immobile clonato",
      description: "Immobile clonato con successo",
    },
    prePublish: {
      title: {
        title_one:
          "C'è {{count}} immobile in pre-approvazione. Completa i dati mancanti per approvarlo!",
        title_other:
          "Ci sono {{count}} immobili in pre-approvazione. Completa i dati mancanti per approvarli!",
      },
      message: "Mostra gli immobili",
    },
  },
  "*": "Qualsiasi",
  errorsToast: {
    noEmail: {
      title: "Impossibile inviare email",
      subtitle: "Il cliene non è abilitato a ricevere email",
    },
    unauthorized: {
      title: "Non autorizzato",
      description: "Ops! Non hai i permessi",
    },
    InternalServerError: {
      title: "Errore interno",
      description: "Ops! Errore interno del server",
    },
    unexpectedError: {
      title: "Problema inatteso",
      description: "Ops! Errore imprevisto",
    },
    abbreviationAlreadyInUse: {
      title: "Abbreviazione non valida",
      description: "Abbreviazione già utilizzata per un'altra agenzia",
    },
    removedRealEstate: {
      title: "Immobile rimosso",
      description: "Immobile eliminato con successo",
    },
    removedDemand: {
      title: "Richiesta rimossa",
      description: "Richiesta rimossa con successo",
    },
    customerNotFound: {
      title: "Errore",
      description: "Cliente non trovato",
    },
    agencyNotFound: {
      title: "Errore",
      description: "Agenzia non trovata",
    },
  },
  fullCalendar: {
    timeGridDay: "Giorno",
    timeGridWeek: "Settimana",
    dayGridMonth: "Mese",
  },
  agenda: {
    title: "Non è possibile visualizzare l'agenda",
    description: "Sincronizza l'email per visualizzare la tua agenda",
    sync: "Sincronizza",
    agenda: "Agenda",
    findCalendar:
      "In questa sezione troverai il calendario e tutti i tuoi appuntamenti.",
    button: "Disattiva Sincronizzazione",
  },
  calendar: {
    modalAddAppointment: {
      rangeToInvalid: "Orario invalido",
      dateFromInvalid: "Data invalida",
      title: "Titolo dell' appuntamento",
      dateFrom: "Data inizio Appuntamento",
      dateTo: "Data fine Appuntamento",
      place: "Luogo*",
      notes: "Note*",
      name: "Nome",
      estate: "Codice immobile",
      placePlaceHolder: "Inserisci il luogo",
      notesPlaceHolder: "inserisci il testo",
      estatePlaceHolder: "inserisci via dell' immobile",
      noOptionsAvaible: "nessuna opzione disponibile",
      confirm: "Conferma",
      delete: "Elimina",
      time: "Orario",
      otherEvents: "Altri appuntamenti",
      errors: {
        fieldRequired: "Campo Richiesto",
        client: "Cliente non presente",
        date: "Data invalida",
        conflictTime: "Esiste gia un'altro appuntamento per quell'ora",
      },
    },
  },
  dashboard: {
    title: "Dashboard",
    description:
      "In questa sezione troverai alcuni dati utili e dei suggerimenti per migliorare la tua azienda",
    cardExpiringDemands: {
      title: "Richieste in scadenza",
      description:
        "Alcune richieste che hai effettuato precedentemente stanno per scadere.",
    },
    cardExpiredDemands: {
      title: "Richieste scadute",
      description:
        "Alcune richieste che hai effettuato precedentemente sono già scadute.",
    },
    increasing: "in aumento",
    calendar: {
      title: "Appuntamenti",
      rangeFrom: "Dalle {{context}}",
      rangeTo: "alle {{context}}",
      linkAgenda: "Vai su agenda",
      emptyEvent: "Non ci sono eventi",
      othersEvent_one: "{{count}} altro evento",
      othersEvent_other: "Altri {{count}} eventi",
    },
    tableGlobalCrossings: {
      title: "Incroci globali",
      seeAll: "Vedi tutti",
      newCrossings: "Nuovi incroci",
      more: "{{count}}% in più",
      new_one: "{{count}} nuovo",
      new_other: "{{count}} nuovi",
      crossings_one: "{{count}} incrocio",
      crossings_other: "{{count}} incroci",
    },
    tableDormantRealEstates: {
      title: "Immobili dormienti",
      seeAll: "Vedi tutti",
      headTable: {
        typologyRealestate: "tipologia dell'immobile",
        enteredAt: "Inserito il",
        inactiveSince: "Inattivo da",
        days_one: "{{count}} giorno",
        days_other: "{{count}} giorni",
      },
    },
    tableDormantDemands: {
      title: "Richieste dormienti",
      seeAll: "Vedi tutte",
      headTable: {
        customerName: "Nome del cliente",
        lastActivity: "Ultima attività",
        inactiveSince: "Inattivo da",
        days_one: "{{count}} giorno",
        days_other: "{{count}} giorni",
      },
    },
    tableSospensive: {
      title: "Venduti con sospensiva",
      seeAll: "Vedi tutti",
      headTable: {
        customerName: "Immobile",
        lastActivity: "Data",
        inactiveSince: "Scadenza",
        days_one: "giorno",
        days_other: "giorni",
      },
    },
    tableMediations: {
      title: "Mandati in scadenza",
      seeAll: "See all",
      headTable: {
        customerName: "RealEstate",
        lastActivity: "Date",
        inactiveSince: "Expiration",
        days_one: "day",
        days_other: "days",
      },
    },
  },
  matches: {
    title: "Incroci",
    description: "La lista dei tuoi incroci",
    placeHolderSearchBar: "Barra di ricerca...",
    labelFilterButton: "Filtra per",
    firstItemFilter: "",
    headTable: {
      customer: "Cliente",
      demand: "Richiesta",
      matches: "Incroci",
      consultant: "Consulente",
    },
    button: {
      getInTouch: "Entra in contatto",
    },
    senderEmail: {
      success: "Email inviata con successo",
      error: "Errore: Cliente non è abilitato a ricevere email",
      title: "Entra in contatto",
    },
  },
  messages: {
    title: "Messaggi",
    description: "La lista dei tuoi messaggi",
    emptyMessageList: "Nessun messaggio trovato",
    markAsUnread: "Segna come ancora da leggere",
    markAsRead: "Segna come già letto",
    relatedRealEstate: "Immobile correlato",
    realEstateInfo: "Informazioni immobile",
  },
  portals: {
    publish: "Pubblica",
    delete: "Rimuovi",
    close: "Chiudi",
    titlePublish: "Pubblica su",
    titleDelete: "Rimuovi da",
    showcase: "Vetrina",
    success: {
      publish: "Immobile pubblicato correttamente su {{context}}",
      delete: "Immobile rimosso da {{context}}",
    },
    errors: {
      publish: "Errore: Non è stato possibile pubblicare su {{context}}",
      delete:
        "Errore:Non è stato possibile rimuovere l'immobile da {{context}}",
    },
  },
  floorLevel: {
    attic: "Attico",
    earthRetainingWall: "Controterra",
    building: "Edificio",
    multistorey: "Multi piano",
    nobleFloor: "Piano nobile",
    mezzanine: "Piano rialzato",
    groundFloor: "Piano terra",
    basement: "Seminterrato",
    onTwoLevels: "Su due livelli",
    topFloor: "Piano superiore",
    notProvided: "No fornito",
  },
  demandSource: {
    internal: "Interni",
    external: "Esterni",
    all: "Tutti",
  },
  typeExternal: {
    all: "Tutti",
    private: "Privati",
    agency: "Agenzia",
    auction: "Asta",
  },
  collaboration: {
    yes: "Sì",
    no: "No",
    onHold: "In Attesa",
    null: "Non verificata",
  },
  negotiation: {
    yes: "Sì",
    no: "No",
    null: "Non verificata",
  },
  statusDemand: {
    active: "Attivi",
    inactive: "Rimossi",
    sent: "Inviati",
    sendEmail: "Invia Email",
    moveOnDisactived: "Rimuovi",
    resend: "Reinvia Email",
    moveOnActive: "Attiva",
    elementCount: "Elementi selezionati",
  },
  demandRealEstate: {
    selectAll: "Seleziona tutti",
    deselectAll: "Deseleziona tutti",
  },
  labelsFilterRealEstate: {
    typology: "Tipologia",
    collaboration: "Collaborazione",
    negotiation: "Trattativa",
    contractType: "Tipo contratto",
    state: "Stato",
    approvationState: "Stato approvazione",
    consultant: "Consulente",
    source: "Provenienza",
    typeExt: "Agenzia/Privato",
    priceFrom: "Prezzo Da",
    priceTo: "Prezzo A",
    localsFrom: "Locali Da",
    localsTo: "Locali A",
    placeholder: {
      minPrice: "Prezzo min",
      maxPrice: "Prezzo max",
      minLocals: "Locali min",
      maxLocals: "Locali max",
    },
  },
  labelsFilterCustomers: {
    request: "Richiesta",
    typology: "Tipologia",
    consultant: "Consulente",
  },
  matchesForRealEstatePage: {
    title: "Risultati Incroci per immobile",
    subtitle: "Lista risultati incroci",
    titlesTable: {
      request: "Richiesta",
      customer: "Cliente",
      consultant: "Consulente",
      createdAt: "Creata ",
      status: "Stato Richiesta",
    },
    status: {
      active: "Attivo",
      inactive: "Rimosso",
      sent: "inviato il",
    },
  },
  createConsultant: {
    selectConsultant: "Seleziona Consulente",
  },
  cardDemandInfo: {
    zone: "Zona",
    sale: "Vendita",
    rent: "Affitto",
  },
  noRealEstate: "Nessun immobile in scadenza",
};
export default it;
