export enum RoutesRouter {
  consultants = "/home/consultants",
  login = "/login",
  resetpassword = "/reset-password",
  customers = "/home/customers",
  editCustomer = "/home/customers",
  realEstates = "/home/realestates",
  addRealestates = "/home/realestates/edit",
  addCustomer = "/home/customers/edit",
  addConsultant = "/home/consultants/edit",
  addAgencies = "/home/agencies/edit",
  editConsultant = "/home/consultants",
  pageNotFound = "/page-not-found",
  demands = "/home/demands",
  agency = "/home/agency",
  agenda = "/home/agenda",
  dashboard = "/home/dashboard",
  editAgency = "/home/agency/edit",
  matches = "/home/matches",
  messages = "/home/messages",
  agencies = "/home/agencies",
}

export type IRoutesRouter = `${RoutesRouter}`;
